import * as Yup from 'yup';
import { errorMessages } from 'shared/constants/messages';
import i18n from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constants';
import { PASSWORD_VALIDATOR_REGEX } from './constants';

const getSchema = (schemaConfig: any): any => {
	return Yup.object().shape(schemaConfig);
};

const getSendEmailValidationSchema = () => Yup.object().shape({
	to: Yup.string().email().min(2, errorMessages.minLength(localizationConstants.recipientEmail, 12))
		.required(errorMessages.required(localizationConstants.recipientEmail)).strict(true),
	cc: Yup.string().email(errorMessages.validType(localizationConstants.email, localizationConstants.email)),
	subject: Yup.string().required(errorMessages.required(localizationConstants.subject)).strict(true),
	content: Yup.string().required(errorMessages.required(localizationConstants.content)).strict(true),
});

const getAddLinceseFormValidationSchema = () => Yup.object().shape({
	expiryDate: Yup.date().when('expiryDateType', {
		is: (value: string) => value === localizationConstants.custom,
		then: () => Yup.date().nullable().required(errorMessages.required(localizationConstants.expiryDate)),
	}),
	[localizationConstants.activateAutoLicense]: Yup.array(),
	licenseVersion: Yup.string().required(errorMessages.select(localizationConstants.version)).strict(true),
	typeValue: Yup.number().when('expiryDateType', {
		is: (value: string) => value === localizationConstants.year,
		then: () => Yup.number()
			.min(1, errorMessages.minValue(localizationConstants.value, 1))
			.required(errorMessages.required(localizationConstants.value))
	}).when('expiryDateType', {
		is: (value: string) => value === localizationConstants.month,
		then: () => Yup.number()
			.min(1, errorMessages.minValue(localizationConstants.value, 1))
			.max(12, errorMessages.maxValue(localizationConstants.value, 12))
			.required(errorMessages.required(localizationConstants.value))
	}),
});

const getAddResigoLinceseFormValidationSchema = () => Yup.object().shape({
	expiryDate: Yup.date().when('expiryDateType', {
		is: (value: string) => value === localizationConstants.custom,
		then: () => Yup.date().nullable().required(errorMessages.required(localizationConstants.expiryDate))
	}),
	[localizationConstants.activateAutoLicense]: Yup.array(),
	licenseVersion: Yup.string().required(errorMessages.select(localizationConstants.version)).strict(true),
	typeValue: Yup.number().when('expiryDateType', {
		is: (value: string) => value === localizationConstants.year,
		then: () => Yup.number()
			.min(1, errorMessages.minValue(localizationConstants.value, 1))
			.required(errorMessages.required(localizationConstants.value))
	}).when('expiryDateType', {
		is: (value: string) => value === localizationConstants.month,
		then: () => Yup.number()
			.min(1, errorMessages.minValue(localizationConstants.value, 1))
			.max(12, errorMessages.maxValue(localizationConstants.value, 12))
			.required(errorMessages.required(localizationConstants.value))
	}),
	roomCount: Yup.number()
		.min(1, errorMessages.minValue(localizationConstants.roomCount, 1))
		.required(errorMessages.required(localizationConstants.roomCount)),
});

const getAddHardwareValidationSchema = () => Yup.object().shape({
	hardwareKey: Yup.string().required(errorMessages.required(localizationConstants.hardwareKey))
		.test('len', errorMessages.exactLength(localizationConstants.hardwareKey, 10), val => (val || '').length === 10)
		.matches(/^[1-9][0-9]*$/, errorMessages.hardwareKeyValidate(localizationConstants.hardwareKey)),
	// name1: Yup.string().required(errorMessages.required(localizationConstants.name1)).strict(true),
	// name2: Yup.string().required(errorMessages.required(localizationConstants.name2)).strict(true),
	// street: Yup.string().strict(true),
	// zipCode: Yup.string().required(errorMessages.required(localizationConstants.zipCode)).strict(true),
	// city: Yup.string().required(errorMessages.required(localizationConstants.city)).strict(true),
	// nation: Yup.string().strict(true),
	// phone: Yup.string().required(errorMessages.required(localizationConstants.phone)).strict(true),
	// fax: Yup.string().strict(true),
	email: Yup.string().email(errorMessages.validType(localizationConstants.email, localizationConstants.email)),
	// ust: Yup.string().strict(true),
	expiryDate: Yup.date().when('expiryDateType', {
		is: (value: string) => value === localizationConstants.custom,
		then: () => Yup.date().nullable().required(errorMessages.required(localizationConstants.expiryDate))
	}),
	[localizationConstants.activateAutoLicense]: Yup.array(),
	licenseVersion: Yup.string().required(errorMessages.select(localizationConstants.version)).strict(true),
	typeValue: Yup.number().when('expiryDateType', {
		is: (value: string) => value === localizationConstants.year,
		then: () => Yup.number()
			.min(1, errorMessages.minValue(localizationConstants.value, 1))
			.required(errorMessages.required(localizationConstants.value))
	}).when('expiryDateType', {
		is: (value: string) => value === localizationConstants.month,
		then: () => Yup.number()
			.min(1, errorMessages.minValue(localizationConstants.value, 1))
			.max(12, errorMessages.maxValue(localizationConstants.value, 12))
			.required(errorMessages.required(localizationConstants.value))
	})
});

const getAddResigoHardwareValidationSchema = () => Yup.object().shape({
	privateKey: Yup.string().required(errorMessages.required(localizationConstants.privateKey))
		.test('len', errorMessages.exactLength(localizationConstants.privateKey, 8), val => (val || '').length === 8)
		.matches(/^[1-9][0-9]*$/, errorMessages.hardwareKeyValidate(localizationConstants.privateKey)),
	// name1: Yup.string().required(errorMessages.required(localizationConstants.name1)).strict(true),
	// name2: Yup.string().required(errorMessages.required(localizationConstants.name2)).strict(true),
	// street: Yup.string().strict(true),
	// zipCode: Yup.string().required(errorMessages.required(localizationConstants.zipCode)).strict(true),
	// city: Yup.string().required(errorMessages.required(localizationConstants.city)).strict(true),
	// nation: Yup.string().strict(true),
	// phone: Yup.string().required(errorMessages.required(localizationConstants.phone)).strict(true),
	// fax: Yup.string().strict(true),
	// email: Yup.string().email(errorMessages.validType(localizationConstants.email, localizationConstants.email)),
	// ust: Yup.string().strict(true),
	// expiryDate: Yup.date().when('expiryDateType', {
	// 	is: ((value: string) => value === localizationConstants.custom),
	// 	then: Yup.date().nullable().required(errorMessages.required(localizationConstants.expiryDate))
	// }),
	roomCount: Yup.number()
		.min(1, errorMessages.minValue(localizationConstants.roomCount, 1))
		.required(errorMessages.required(localizationConstants.roomCount)),
	[localizationConstants.activateAutoLicense]: Yup.array(),
	licenseVersion: Yup.string().required(errorMessages.select(localizationConstants.version)).strict(true),
	typeValue: Yup.number().when('expiryDateType', {
		is: (value: string) => value === localizationConstants.year,
		then: () => Yup.number()
			.min(1, errorMessages.minValue(localizationConstants.value, 1))
			.required(errorMessages.required(localizationConstants.value))
	}).when('expiryDateType', {
		is: (value: string) => value === localizationConstants.month,
		then: () => Yup.number()
			.min(1, errorMessages.minValue(localizationConstants.value, 1))
			.max(12, errorMessages.maxValue(localizationConstants.value, 12))
			.required(errorMessages.required(localizationConstants.value))
	})
});

const getEmailTemplateFormValidationSchema = () => Yup.object().shape({
	name: Yup.string().min(2, errorMessages.minLength(localizationConstants.name, 2))
		.required(errorMessages.required(localizationConstants.name)).strict(true),
	subject: Yup.string().min(2, errorMessages.minLength(localizationConstants.subject, 2))
		.required(errorMessages.required(localizationConstants.subject)).strict(true)
});

const getLoginFormValidationSchema = () => Yup.object().shape({
	email: Yup.string().email()
		.required(errorMessages.required(localizationConstants.email)).strict(true),
	password: Yup.string()
		.required(errorMessages.required(localizationConstants.password)).strict(true)
});

const getForgetPasswordValidationSchema = () => Yup.object().shape({
	email: Yup.string().email()
		.required(errorMessages.required(localizationConstants.email)).strict(true),
});

const getPhoenixPasswordValidationSchema = () => Yup.object().shape({
	// password: Yup.string().max(20, errorMessages.maxLength(localizationConstants.password, 20))
	// 	.required(errorMessages.required(localizationConstants.password)).strict(true)
	// 	.matches(PASSWORD_VALIDATOR_REGEX, errorMessages.passwordValidate()),
	password: Yup.string().max(32, errorMessages.maxLength(localizationConstants.password, 32)).required().label('Password'),
	// password: Yup.string()
	// .min(8, 'Password is too short, should be 8 chars minimum')
	// .max(32, 'Password is too long, should be 32 chars maximum')
	// .matches(/(?=.*\d)/, 'Password must contain at least 1 number')
	// .matches(
	//   /[!@#$%^&*(),.?":{}|<>]/,
	//   'Password must contain at least 1 special character'
	// )
	// .matches(
	//   /(?=.*[A-Z])/,
	//   'Password must contain at least 1 upper case letter'
	// )
	// .required()
	// .label('Password'),
	password_confirmation: Yup.string()
		.required(errorMessages.required('Password Confirmation')).strict(true)
		.test('match', errorMessages.passwordMatchValidate(), function (password) {
			return password === this.parent.password;
		})
});

const getResetPasswordValidationSchema = () => Yup.object().shape({
	// email: Yup.string().email()
	// 	.required(errorMessages.required('email')).strict(true),
	password: Yup.string().min(8, errorMessages.minLength(localizationConstants.newPassword, 8))
		.required(errorMessages.required(localizationConstants.newPassword)).strict(true),
	password_confirmation: Yup.string().min(8, errorMessages.minLength(localizationConstants.passwordConfirmation, 8))
		.required(errorMessages.required('Password Confirmation')).strict(true)
		.test('match', errorMessages.passwordMatchValidate(), function (password) {
			return password === this.parent.password;
		})
});

const getUpdatePasswordValidationSchema = () => Yup.object().shape({
	// email: Yup.string().email()
	// 	.required(errorMessages.required('email')).strict(true),
	password: Yup.string().min(8, errorMessages.minLength(localizationConstants.newPassword, 8))
		.required(errorMessages.required(localizationConstants.newPassword)).strict(true),
	confirm_password: Yup.string().min(8, errorMessages.minLength(localizationConstants.passwordConfirmation, 8))
		.required(errorMessages.required('Password Confirmation')).strict(true)
		.test('match', errorMessages.passwordMatchValidate(), function (password) {
			return password === this.parent.password;
		})
});

const getPhoenixResetPasswordValidationSchema = () => Yup.object().shape({
	password: Yup.string().max(32, errorMessages.maxLength(localizationConstants.password, 32))
		.required(errorMessages.required(localizationConstants.newPassword)).strict(true),
	// password: Yup.string().max(20, errorMessages.maxLength(localizationConstants.password, 20))
	// 	.required(errorMessages.required(localizationConstants.newPassword)).strict(true)
	// 	.matches(PASSWORD_VALIDATOR_REGEX, errorMessages.passwordValidate()),
	password_confirmation: Yup.string()
		.required(errorMessages.required('Password Confirmation')).strict(true)
		.test('match', errorMessages.passwordMatchValidate(), function (password) {
			return password === this.parent.password;
		})
});

const getResetMasterPasswordValidationSchema = () => Yup.object().shape({
	newPassword: Yup.string().min(8, errorMessages.minLength(localizationConstants.newPassword, 8))
		.required(errorMessages.required(localizationConstants.newPassword)).strict(true),
	confirmPassword: Yup.string().min(8, errorMessages.minLength(localizationConstants.confirmPassword, 8))
		.required(errorMessages.required(localizationConstants.confirmPassword)).strict(true)
		.test('match', errorMessages.passwordMatchValidate(), function (password) {
			return password === this.parent.newPassword;
		})
});

const getChangePasswordValidationSchema = () => Yup.object().shape({
	old_password: Yup.string().required(errorMessages.required(localizationConstants.oldPassword)).strict(true),
	new_password: Yup.string().min(8, errorMessages.minLength(localizationConstants.oldPassword, 8))
		.required(errorMessages.required(localizationConstants.newPassword)).strict(true),
	new_password_confirmation: Yup.string().min(8, errorMessages.minLength(localizationConstants.passwordConfirmation, 8))
		.required(errorMessages.required(localizationConstants.passwordConfirmation)).strict(true)
		.test('match', errorMessages.passwordMatchValidate(), function (password) {
			return password === this.parent.new_password;
		})
});

const getUpdateProfileValidationSchema = () => Yup.object().shape({
	name: Yup.string().required(errorMessages.required(localizationConstants.name)).strict(true),
	email: Yup.string().email(errorMessages.validType(localizationConstants.email, 'email'))
		.required(errorMessages.required(localizationConstants.email)).strict(true),
	language: Yup.string()
		.required(errorMessages.required(localizationConstants.language)).strict(true),
});


const getRoleFormValidationSchema = () => Yup.object().shape({
	name: Yup.string().min(2, errorMessages.minLength(localizationConstants.name, 2))
		.required(errorMessages.required(localizationConstants.name)).strict(true),
});

const getCreateTaxRuleValidationSchema = () => Yup.object().shape({
	country: Yup.string().required(errorMessages.required(localizationConstants.country)).strict(true),
	tax_set: Yup.string().required(errorMessages.required(localizationConstants.taxSet)).strict(true),
	// tax_rate_name: Yup.string().required(errorMessages.required(localizationConstants.taxRateName)).strict(true),
	// tax_rate: Yup.number().min(0, errorMessages.minValue(localizationConstants.taxRate, 0)).required(errorMessages.required(localizationConstants.taxRate)).strict(true),
});

const getUserFormValidationSchema = () => Yup.object().shape({
	name: Yup.string().min(2, errorMessages.minLength(localizationConstants.name, 2))
		.required(errorMessages.required(localizationConstants.name)).strict(true),
	email: Yup.string().email(errorMessages.validType(localizationConstants.email, localizationConstants.email)).matches(/@[^.]*\./, errorMessages.validType(localizationConstants.email, localizationConstants.email))
		.required(errorMessages.required(localizationConstants.email)).strict(true),
	password: Yup.string()
		.required(errorMessages.required(localizationConstants.password)).strict(true),
	role: Yup.string().required(errorMessages.select(localizationConstants.role)).strict(true),
	status: Yup.string().required(errorMessages.select(localizationConstants.status)).strict(true),
	language: Yup.string()
		.required(errorMessages.required(localizationConstants.language)).strict(true),
});

const getEditUserFormValidationSchema = () => Yup.object().shape({
	name: Yup.string().min(2, errorMessages.minLength(localizationConstants.name, 2))
		.required(errorMessages.required(localizationConstants.name)).strict(true),
	email: Yup.string().email(errorMessages.validType(localizationConstants.email, localizationConstants.email))
		.required(errorMessages.required(localizationConstants.email)).strict(true),
	password: Yup.string().strict(true),
	role: Yup.string().required(errorMessages.select(localizationConstants.role)),
	language: Yup.string()
		.required(errorMessages.required(localizationConstants.language)).strict(true),
	status: Yup.string().required(errorMessages.select(localizationConstants.status)),
});

const getNewCustomerValidationSchema = () => Yup.object().shape({
	name: Yup.string().required(errorMessages.required(localizationConstants.name)).strict(true),
	customer_id: Yup.number()
		.min(1, errorMessages.minValue(localizationConstants.debAutoId, 1)).strict(true)
		.max(99999, errorMessages.maxValue(localizationConstants.debAutoId, 99999)).strict(true)
		.required(errorMessages.required(localizationConstants.debAutoId)).strict(true),
	// street: Yup.string().required(errorMessages.required(localizationConstants.street)).strict(true),
	// zipCode: Yup.string().required(errorMessages.required(localizationConstants.zipCode)).strict(true),
	// city: Yup.string().required(errorMessages.required(localizationConstants.city)).strict(true),
});

const getAddCredentialValidationSchema = () => Yup.object().shape({
	notes: Yup.string().required(errorMessages.required(localizationConstants.notes)).strict(true),
	type: Yup.string().required(errorMessages.select(localizationConstants.type)),
	status: Yup.string().required(errorMessages.select(localizationConstants.status)),
	apiId: Yup.string().required(errorMessages.required(localizationConstants.apiId)).strict(true),
	apiSecretKey: Yup.string().required(errorMessages.required(localizationConstants.apiSecretKey)).strict(true),
});

const getUpdateCredentialValidationSchema = () => Yup.object().shape({
	notes: Yup.string().required(errorMessages.required(localizationConstants.notes)).strict(true),
	status: Yup.string().required(errorMessages.select(localizationConstants.status)),
});

const getModuleValidationSchema = () => Yup.object().shape({
	name: Yup.string().required(errorMessages.required(localizationConstants.name)).strict(true),
	price: Yup.number()
		.min(0, errorMessages.minValue(localizationConstants.price, 0))
		.required(errorMessages.required(localizationConstants.price)),
});

const getPackageValidationSchema = () => Yup.object().shape({
	name: Yup.string().required(errorMessages.required(localizationConstants.name)).strict(true),
	defaultUser: Yup.number()
		.min(0, errorMessages.minValue(localizationConstants.defaultUser, 0))
	// .required(errorMessages.required(localizationConstants.defaultUser)),
});

const getPhoenixLicenseManageSubscriptionValidationSchema = () => Yup.object().shape({
	version: Yup.string().required(errorMessages.select(localizationConstants.version)).strict(true),
	additionalUser: Yup.number()
		.min(0, errorMessages.minValue(localizationConstants.user, 0))
		.required(errorMessages.required(localizationConstants.user)),
});
const getJumpbirdManageSepaSubscriptionValidationSchema = () => Yup.object().shape({
	reason: Yup.string().when('status', {
		is: "0",
		then: () => Yup.string().required(errorMessages.required(localizationConstants.reason)).strict(true)
	})
});

const getJumpbirdAssignSepaSubscriptionValidationSchema = () => Yup.object().shape({
	subscription: Yup.string().required(errorMessages.required(localizationConstants.package)).strict(true),
	billingCycle: Yup.string().required(errorMessages.required(localizationConstants.billingCycles)).strict(true),
	bankAccountOwnerName: Yup.string().required(errorMessages.required(localizationConstants.bankAccountOwnerName)).strict(true),
	iban: Yup.string().required(errorMessages.required(localizationConstants.iban)).strict(true),
	bic: Yup.string().required(errorMessages.required(localizationConstants.bic)).strict(true),
	// expiryDate: Yup.string();
});

const getManagePhoenixLicenseExpiryDateFormValidationSchema = () => Yup.object().shape({
	expiryDate: Yup.date().nullable().required(errorMessages.required(localizationConstants.expiryDate))
});

const getPhoenixLicenseManageUserValidationSchema = () => Yup.object().shape({
	additionalUser: Yup.number()
		.min(0, errorMessages.minValue(localizationConstants.user, 0))
		.required(errorMessages.required(localizationConstants.user)),
});

const getCSVFileValidationSchema = () => Yup.object().shape({
	file: Yup.mixed().required(),
});

const getAddCustomerCommissionFormValidationSchema = () => Yup.object().shape({
	customerId: Yup.string().required(errorMessages.required(localizationConstants.customerId)),
	contractBeginDate: Yup.date().nullable().required(errorMessages.required(localizationConstants.contractBeginDate)),
});

const getCreatePosbillInvoiceFormValidationSchema = () => Yup.object().shape({
	invoices: Yup.array(Yup.object().shape({
		part_id: Yup.string().required(errorMessages.required(localizationConstants.item)).strict(true),
		quantity: Yup.number().when("part_id", {
			is: (part_id: any) => !!part_id,
			then: () => Yup.number().required(localizationConstants.quantity).min(1, errorMessages.minValue(localizationConstants.quantity, 1))
		}),
		price: Yup.number().when("part_id", {
			is: (part_id: any) => !!part_id,
			then: () => Yup.number().required(localizationConstants.price).min(0, errorMessages.minValue(localizationConstants.price, 0))
		})
	})).min(1, errorMessages.minValue(localizationConstants.invoice, 1))
});

const getCreateOrUpdateInventoriesFormValidationSchema = () => Yup.object().shape({
	groupName: Yup.string().required(errorMessages.required(localizationConstants.inventoryGroupName)).strict(true),
	productName: Yup.object().required(errorMessages.required(localizationConstants.product)).strict(true),
	invoices: Yup.array(Yup.object().shape({
		part_id: Yup.string().required(errorMessages.required(localizationConstants.item)).strict(true),
		quantity: Yup.number().when("part_id", {
			is: (part_id: any) => !!part_id,
			then: () => Yup.number().required(localizationConstants.quantity).min(1, errorMessages.minValue(localizationConstants.quantity, 1))
		}),
		price: Yup.number().when("part_id", {
			is: (part_id: any) => !!part_id,
			then: () => Yup.number().required(localizationConstants.price).min(0, errorMessages.minValue(localizationConstants.price, 0))
		})
	})).min(1, errorMessages.minValue(localizationConstants.inventories, 1))
});

const getManageProvisionReportBankExportFormValidationSchema = () => Yup.object().shape({
	tradingDate: Yup.date().nullable().required(errorMessages.required(localizationConstants.tradingDate))
});

const getLicenseModuleValidationSchema = () => Yup.object().shape({
	name: Yup.string().required(errorMessages.required(localizationConstants.name)).strict(true),
	classPosbill: Yup.string().required(errorMessages.required(localizationConstants.classPosbill)).strict(true),
});

const getRegisterUserValidationSchema = () => Yup.object().shape({
	name: Yup.string().min(2, errorMessages.minLength(localizationConstants.name, 2))
		.required(errorMessages.required(localizationConstants.name)).strict(true),
	email: Yup.string().email()
		.required(errorMessages.required(localizationConstants.email)).strict(true),
	password: Yup.string().min(8, errorMessages.minLength(localizationConstants.password, 8))
		.required(errorMessages.required(localizationConstants.password)).strict(true),
	confirmPassword: Yup.string()
		.test('match', errorMessages.passwordMatchValidate(), function (password) {
			return password === this.parent.password
		}),
	debAuto: Yup.number()
		.min(1, errorMessages.minValue(localizationConstants.debAutoId, 1))
		.max(99999, errorMessages.maxValue(localizationConstants.debAutoId, 99999))
		.required(errorMessages.required(localizationConstants.debAutoId)).strict(true)
});

const getTaxPayerFormValidationSchema = () => Yup.object().shape({
	general_information: Yup.object().shape({
		// person: Yup.string()
		// 	.trim()
		// 	.oneOf(['natural', 'legal'], errorMessages.select(localizationConstants.person))
		// 	.required(errorMessages.select(localizationConstants.person)),
		information: Yup.object().shape({
			person: Yup.string()
			.trim()
			.oneOf(['natural', 'legal'], errorMessages.select(localizationConstants.person))
			.required(errorMessages.select(localizationConstants.person)),
			first_name: Yup.string()
				.when('person', {
					is: (value: string) => value === 'natural',
					then: (schema) => schema.required(errorMessages.required(localizationConstants.firstName)),
					otherwise: (schema) => schema.optional()
				}),
			last_name: Yup.string()
				.when('person', {
					is: (value: string) => value === 'natural',
					then: (schema) => schema.required(errorMessages.required(localizationConstants.lastName)),
					otherwise: (schema) => schema.optional()
				}),
			identification_number: Yup.string()
				.when('person', {
					is: (value: string) => value === 'natural',
					then: (schema) => schema.matches(/^\d{11}$/, errorMessages.validType(localizationConstants.identificationNumber, localizationConstants.identificationNumber))
						.required(errorMessages.required(localizationConstants.identificationNumber)),
					otherwise: (schema) => schema.optional()
				}),
			title: Yup.string()
				.when('person', {
					is: (value: string) => value === 'natural',
					then: (schema) => schema.required(errorMessages.required(localizationConstants.title)),
					otherwise: (schema) => schema.optional()
				}),
			prefix: Yup.string()
				.when('person', {
					is: (value: string) => value === 'natural',
					then: (schema) => schema.required(errorMessages.required(localizationConstants.prefix)),
					otherwise: (schema) => schema.optional()
				}),
			salutation: Yup.string()
				.when('person', {
					is: (value: string) => value === 'natural',
					then: (schema) => schema.required(errorMessages.required(localizationConstants.salutation)),
					otherwise: (schema) => schema.optional()
				}),
			suffix: Yup.string()
				.when('person', {
					is: (value: string) => value === 'natural',
					then: (schema) => schema.required(errorMessages.required(localizationConstants.suffix)),
					otherwise: (schema) => schema.optional()
				}),
			company_name: Yup.string()
				.when('person', {
					is: (value: string) => value === 'legal',
					then: (schema) => schema.required(errorMessages.required(localizationConstants.companyName)),
					otherwise: (schema) => schema.optional()
				}),
			legal_form: Yup.string()
				.when('person', {
					is: (value: string) => value === 'legal',
					then: (schema) => schema.required(errorMessages.select(localizationConstants.legalForm)),
					otherwise: (schema) => schema.optional()
				}),
			web_address: Yup.string()
				.transform((value) => {
					if (value && !value.startsWith("http://") && !value.startsWith("https://")) {
						return `http://${value}`;
					}
					return value;
				})
				.url('Invalid URL format')
				.required(errorMessages.required(localizationConstants.website)),
		}),
		address: Yup.object({
			post_address: Yup.object({
				address_additional: Yup.string().optional(),
				house_number: Yup.string()
					.required(errorMessages.required(localizationConstants.houseNumber))
					.strict(true),
				house_number_suffix: Yup.string().optional(),
				street: Yup.string()
					.required(errorMessages.required(localizationConstants.street))
					.strict(true),
				town: Yup.string()
					.required(errorMessages.required(localizationConstants.town))
					.strict(true),
				zip_code: Yup.string()
					.matches(/^\d{5}$/, errorMessages.validType(localizationConstants.zipCode, localizationConstants.zipCode))
					.required(errorMessages.required(localizationConstants.zipCode))
					.strict(true),
			}),
		}),
	}),
	metadata: Yup.array()
      .of(
        Yup.object().shape({
          key: Yup.string().required(errorMessages.required(localizationConstants.key)),
          value: Yup.string().required(errorMessages.required(localizationConstants.value)),
        })
      ),
	tax_number: Yup.string()
		.matches(/^\d{13}$/, errorMessages.validType(localizationConstants.taxNumber, localizationConstants.taxNumber))
		.required(errorMessages.required(localizationConstants.taxNumber))
		.strict(true),
	tax_office_number: Yup.string()
		.required(errorMessages.required(localizationConstants.taxOfficeNumber))
		.strict(true),
	vat_number: Yup.string()
		.matches(/^DE\d{9}$/, errorMessages.validType(localizationConstants.vatNumber, localizationConstants.vatNumber))
		.required(errorMessages.required(localizationConstants.vatNumber))
		.strict(true)
});

const getEstablishmentFormValidationSchema = () => Yup.object().shape({
	address: Yup.object().shape({
	  	house_number: Yup.string()
			.required(errorMessages.required(localizationConstants.houseNumber))
			.matches(/^[0-9]{1,4}$/, errorMessages.validType(localizationConstants.houseNumber, localizationConstants.houseNumber))
			.max(4, errorMessages.maxLength(localizationConstants.houseNumber, 5)),
	  	street: Yup.string()
			.required(errorMessages.required(localizationConstants.street))
			.max(72, errorMessages.maxLength(localizationConstants.houseNumber, 73)),
	  	town: Yup.string()
			.required(errorMessages.required(localizationConstants.town))
			.max(72, errorMessages.maxLength(localizationConstants.town, 73)),
		zip_code: Yup.string()
			.required(errorMessages.required(localizationConstants.zipCode))
			.matches(/^([0]{1}[1-9]{1}[0-9]{3})|([1-9]{1}[0-9]{4})$/, errorMessages.validType(localizationConstants.zipCode, localizationConstants.zipCode))
			.length(5, errorMessages.exactLength(localizationConstants.town, 5)),
		house_number_suffix: Yup.string().optional()
			.max(20, errorMessages.maxLength(localizationConstants.houseNumberSuffix, 21)),
		address_additional: Yup.string().optional()
			.max(46, errorMessages.maxLength(localizationConstants.houseNumberSuffix, 47)),
	}),
	decommissioning_date: Yup.string().nullable(),
  	designation: Yup.string().optional()
		.max(200, errorMessages.maxLength(localizationConstants.designation, 201)),
	metadata: Yup.array()
		.of(
		  Yup.object().shape({
			key: Yup.string().required(errorMessages.required(localizationConstants.key)),
			value: Yup.string().required(errorMessages.required(localizationConstants.value)),
		  })
		),
	remarks: Yup.string().optional()
		.min(1)
		.max(1000, errorMessages.maxLength(localizationConstants.remarks, 1001)),
});

const getTSSFormValidationSchema = () => Yup.object().shape({
    bsi_id: Yup.string()
        .required(errorMessages.required(localizationConstants.bsiId))
        .matches(/^\d{4}-\d{4}$/, errorMessages.validType(localizationConstants.bsiId, "format(xxxx-xxxx)")),
	serial_number: Yup.string()
        .required(errorMessages.required(localizationConstants.tssSerialNumber))
		.matches(
			/^[a-fA-F0-9]{64}$/,
			errorMessages.validType(localizationConstants.tssSerialNumber, localizationConstants.tssSerialNumber)
		),
    date_commissioning: Yup.string()
        .required(errorMessages.required(localizationConstants.dateCommissioning)),
    type: Yup.mixed()
        .oneOf(["1", "2", "3"])
        .required(errorMessages.required(localizationConstants.type)),
	metadata: Yup.array()
		.of(
		  Yup.object().shape({
			key: Yup.string().required(errorMessages.required(localizationConstants.key)),
			value: Yup.string().required(errorMessages.required(localizationConstants.value)),
		  })
		),
});

const getClientFormValidationSchema = () => 
    Yup.object().shape({
		tss_id: Yup.string()
            .required(errorMessages.required(localizationConstants.tss)),
        date_acquisition: Yup.string()
            .required(errorMessages.required(localizationConstants.dateAcquisition)),
        date_commissioning: Yup.string()
            .required(errorMessages.required(localizationConstants.dateCommissioning)),
        date_decommissioning: Yup.string().optional(),
        decommissioning_reason: Yup.string()
			.optional()
            .max(1000, errorMessages.maxLength(localizationConstants.decommissioningReason, 1000)),
        establishment_id: Yup.string()
            .optional()
            .matches(
                /^[a-f0-9]{8}-?[a-f0-9]{4}-?4[a-f0-9]{3}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}$/,
                errorMessages.validType(localizationConstants.establishmentId, localizationConstants.uuidFormat)
            ),
        manufacturer: Yup.string()
            .required(errorMessages.required(localizationConstants.manufacturer))
            .max(1000, errorMessages.maxLength(localizationConstants.manufacturer, 1000)),
        metadata: Yup.array()
            .of(
                Yup.object().shape({
                    key: Yup.string().required(errorMessages.required(localizationConstants.key)),
                    value: Yup.string().required(errorMessages.required(localizationConstants.value)),
                })
            ),
        model: Yup.string()
            .required(errorMessages.required(localizationConstants.model))
            .max(1000, errorMessages.maxLength(localizationConstants.model, 1000)),
        remarks: Yup.string()
            .notRequired()
            .max(1000, errorMessages.maxLength(localizationConstants.remarks, 1000)),
        serial_number: Yup.string()
            .required(errorMessages.required(localizationConstants.tssSerialNumber))
            .max(1000, errorMessages.maxLength(localizationConstants.tssSerialNumber, 1000)),
        software: Yup.string()
            .required(errorMessages.required(localizationConstants.software))
            .max(1000, errorMessages.maxLength(localizationConstants.software, 1000)),
        software_version: Yup.string()
            .notRequired()
            .max(1000, errorMessages.maxLength(localizationConstants.softwareVersion, 1000)),
        type: Yup.mixed()
            .oneOf(["1", "2", "3", "4", "5"], errorMessages.validType(localizationConstants.type, localizationConstants.type))
            .required(errorMessages.required(localizationConstants.type)),
        type_other: Yup.string()
            .notRequired()
            .max(1000, errorMessages.maxLength(localizationConstants.typeOther, 1000)),
    });


let sendEmailValidationSchema = getSendEmailValidationSchema();

let addLinceseFormValidationSchema = getAddLinceseFormValidationSchema();

let addResigoLinceseFormValidationSchema = getAddResigoLinceseFormValidationSchema();

let addHardwareValidationSchema = getAddHardwareValidationSchema();

let addResigoHardwareValidationSchema = getAddResigoHardwareValidationSchema();

let emailTemplateFormValidationSchema = getEmailTemplateFormValidationSchema();

let loginFormValidationSchema = getLoginFormValidationSchema();

let forgetPasswordValidationSchema = getForgetPasswordValidationSchema();

let phoenixPasswordValidationSchema = getPhoenixPasswordValidationSchema();

let resetPasswordValidationSchema = getResetPasswordValidationSchema();

let updatePasswordValidationSchema = getUpdatePasswordValidationSchema();

let phoenixResetPasswordValidationSchema = getPhoenixResetPasswordValidationSchema();

let changePasswordValidationSchema = getChangePasswordValidationSchema();

let updateProfileValidationSchema = getUpdateProfileValidationSchema();

let roleFormValidationSchema = getRoleFormValidationSchema();

let userFormValidationSchema = getUserFormValidationSchema();

let editUserFormValidationSchema = getEditUserFormValidationSchema();

let newCustomerValidationSchema = getNewCustomerValidationSchema();

let addCredentialValidationSchema = getAddCredentialValidationSchema();

let updateCredentialValidationSchema = getUpdateCredentialValidationSchema();

let moduleValidationSchema = getModuleValidationSchema();

let packageValidationSchema = getPackageValidationSchema();

let resetMasterPasswordValidationSchema = getResetMasterPasswordValidationSchema();

let jumpbirdManageSepaSubscriptionValidationSchema = getJumpbirdManageSepaSubscriptionValidationSchema();

let jumpbirdAssignSepaSubscriptionValidationSchema = getJumpbirdAssignSepaSubscriptionValidationSchema();

let phoenixLicenseManageSubscriptionValidationSchema = getPhoenixLicenseManageSubscriptionValidationSchema();

let managePhoenixLicenseExpiryDateFormValidationSchema = getManagePhoenixLicenseExpiryDateFormValidationSchema();

let phoenixLicenseManageUserValidationSchema = getPhoenixLicenseManageUserValidationSchema();

let createTaxRuleValidationSchema = getCreateTaxRuleValidationSchema();

let csvFileValidationSchema = getCSVFileValidationSchema();

let addCustomerCommissionFormValidationSchema = getAddCustomerCommissionFormValidationSchema();

let createPosbillInvoiceFormValidationSchema = getCreatePosbillInvoiceFormValidationSchema();

let createOrUpdateInventoriesFormValidationSchema = getCreateOrUpdateInventoriesFormValidationSchema();

let manageProvisionReportBankExportFormValidationSchema = getManageProvisionReportBankExportFormValidationSchema();

let licenseModuleValidationSchema = getLicenseModuleValidationSchema();

let registerUserValidationSchema = getRegisterUserValidationSchema();

let taxPayerFormValidationSchema = getTaxPayerFormValidationSchema();

let establishmentFormValidationSchema = getEstablishmentFormValidationSchema();

let tssFormValidationSchema = getTSSFormValidationSchema();

let clientFormValidationSchema = getClientFormValidationSchema();

i18n.on('languageChanged', () => {
	sendEmailValidationSchema = getSendEmailValidationSchema();
	addLinceseFormValidationSchema = getAddLinceseFormValidationSchema();
	addResigoLinceseFormValidationSchema = getAddResigoLinceseFormValidationSchema();
	addHardwareValidationSchema = getAddHardwareValidationSchema();
	addResigoHardwareValidationSchema = getAddResigoHardwareValidationSchema();
	emailTemplateFormValidationSchema = getEmailTemplateFormValidationSchema();
	loginFormValidationSchema = getLoginFormValidationSchema();
	forgetPasswordValidationSchema = getForgetPasswordValidationSchema();
	phoenixPasswordValidationSchema = getPhoenixPasswordValidationSchema();
	resetPasswordValidationSchema = getResetPasswordValidationSchema();
	updatePasswordValidationSchema = getUpdatePasswordValidationSchema();
	phoenixResetPasswordValidationSchema = getPhoenixResetPasswordValidationSchema();
	changePasswordValidationSchema = getChangePasswordValidationSchema();
	updateProfileValidationSchema = getUpdateProfileValidationSchema();
	roleFormValidationSchema = getRoleFormValidationSchema();
	userFormValidationSchema = getUserFormValidationSchema();
	editUserFormValidationSchema = getEditUserFormValidationSchema();
	newCustomerValidationSchema = getNewCustomerValidationSchema();
	addCredentialValidationSchema = getAddCredentialValidationSchema();
	updateCredentialValidationSchema = getUpdateCredentialValidationSchema();
	moduleValidationSchema = getModuleValidationSchema();
	packageValidationSchema = getPackageValidationSchema();
	resetMasterPasswordValidationSchema = getResetMasterPasswordValidationSchema();
	jumpbirdManageSepaSubscriptionValidationSchema = getJumpbirdManageSepaSubscriptionValidationSchema();
	jumpbirdAssignSepaSubscriptionValidationSchema = getJumpbirdAssignSepaSubscriptionValidationSchema();
	phoenixLicenseManageSubscriptionValidationSchema = getPhoenixLicenseManageSubscriptionValidationSchema();
	managePhoenixLicenseExpiryDateFormValidationSchema = getManagePhoenixLicenseExpiryDateFormValidationSchema();
	phoenixLicenseManageUserValidationSchema = getPhoenixLicenseManageUserValidationSchema();
	createTaxRuleValidationSchema = getCreateTaxRuleValidationSchema();
	csvFileValidationSchema = getCSVFileValidationSchema();
	addCustomerCommissionFormValidationSchema = getAddCustomerCommissionFormValidationSchema();
	createPosbillInvoiceFormValidationSchema = getCreatePosbillInvoiceFormValidationSchema();
	createOrUpdateInventoriesFormValidationSchema = getCreateOrUpdateInventoriesFormValidationSchema();
	manageProvisionReportBankExportFormValidationSchema = getManageProvisionReportBankExportFormValidationSchema();
	licenseModuleValidationSchema = getLicenseModuleValidationSchema();
	registerUserValidationSchema = getRegisterUserValidationSchema();
	taxPayerFormValidationSchema = getTaxPayerFormValidationSchema();
	establishmentFormValidationSchema = getEstablishmentFormValidationSchema();
	tssFormValidationSchema = getTSSFormValidationSchema();
	clientFormValidationSchema = getClientFormValidationSchema();
});

export {
	sendEmailValidationSchema,
	addLinceseFormValidationSchema,
	addResigoLinceseFormValidationSchema,
	addHardwareValidationSchema,
	addResigoHardwareValidationSchema,
	emailTemplateFormValidationSchema,
	loginFormValidationSchema,
	forgetPasswordValidationSchema,
	phoenixPasswordValidationSchema,
	resetPasswordValidationSchema,
	updatePasswordValidationSchema,
	phoenixResetPasswordValidationSchema,
	changePasswordValidationSchema,
	updateProfileValidationSchema,
	roleFormValidationSchema,
	getSchema,
	userFormValidationSchema,
	editUserFormValidationSchema,
	newCustomerValidationSchema,
	addCredentialValidationSchema,
	updateCredentialValidationSchema,
	moduleValidationSchema,
	packageValidationSchema,
	resetMasterPasswordValidationSchema,
	jumpbirdManageSepaSubscriptionValidationSchema,
	jumpbirdAssignSepaSubscriptionValidationSchema,
	phoenixLicenseManageSubscriptionValidationSchema,
	managePhoenixLicenseExpiryDateFormValidationSchema,
	createTaxRuleValidationSchema,
	phoenixLicenseManageUserValidationSchema,
	csvFileValidationSchema,
	addCustomerCommissionFormValidationSchema,
	createPosbillInvoiceFormValidationSchema,
	createOrUpdateInventoriesFormValidationSchema,
	manageProvisionReportBankExportFormValidationSchema,
	licenseModuleValidationSchema,
	registerUserValidationSchema,
	taxPayerFormValidationSchema,
	establishmentFormValidationSchema,
	tssFormValidationSchema,
	clientFormValidationSchema
};
