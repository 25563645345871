import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/datatables.min.css';
import './assets/styles/index.scss';
import Layout from 'shared/hoc/layout/component/layout.component';
import Spinner from 'shared/components/spinner/spinner';

// import containers, for which lazy loading is not needed, as they will be rendered for all users
import NotFound from 'shared/components/404/404';
import Profile from 'features/profile/container/profile';
import Login from 'features/login/component/loginForm';
import ForgetPassword from 'features/password/component/forgetPasswordForm';
import ResetPassword from 'features/password/component/resetPasswordForm';
import PhoenixForgetPassword from 'features/phoenix/company/container/forgetPassword';
import PhoenixResetPassword from 'features/phoenix/company/container/resetPassword';
import CompanyVerification from 'features/companyVerification/component/validate';
import PhoenixOnboarding from 'features/phoenixOnboarding/component/phoenixOnboarding';

/*
* import async components in order to lazy load the containers, which will be rendered conditionally
* based on the permissions user is having
*/
import * as asyncComponents from 'shared/hoc/asyncComponents';
import { useSelector } from 'react-redux';
import { State } from 'shared/interface';
import Dashboard from 'features/dashboard/component/dashboard';
import { checkPermission } from 'shared/util/permission';

const App: React.FC = () => {
	const isLoggedIn = useSelector((state: State) => state.auth.authData.auth);
	const isPublicPage = window.location.pathname.includes('company/validate/');
	const isPhoenixOnboarding = window.location.pathname.includes('phoenix/onboarding');
	const isPhoenixCompanyPasswordForget = window.location.pathname.includes('phoenix/company/forget-password');
	const isPhoenixCompanyPasswordReset = window.location.pathname.includes('phoenix/company/reset-password/');

	const { SuperAdminOrganization, LicenseOrganization, SupportOrganization,
		OrganizationIncomingLicenses, OrganizationIncomingLicenseDetail, PhoenixIncomingLicenses, JumpbirdIncomingLicenses,
		SuperAdminOrgDetail, LicenseAdminOrgDetail, CustomerLicense, LicenseModuleList,
		Turnover, ReaList, ReportList, ReportDetail,
		InvoiceGroupList, InvoiceGroupDetail, InventoryGroupList, InventoriesList, TaxRulesList, CustomerList, DocumentList,
		IncomingLicenses, MachineList, AutoUpdateMachineList, LicenseNumbers, OrderCodeExtensions, ConfigurationsList, LicenseDecoder,
		Users, AddUser, EditUser, Permissions, Roles, ClientsList, OrdersList, OrderDetail,
		Company, CompanyDetail, ModuleList, PackageList, ApiKeyList, JumpbirdCompany, JumpbirdCompanyDetail, JumpbirdPackageList,
		TSEDeviceList, TSEDeviceActivityLog, TSECustomerList, TSECustomerListV2, TSECustomerDetail,
		ResigoMachine, ResigoMachineDetail, ResigoLicense, ResigoLicenseDecoder, ZMList, OfferToolBundleGroup, OfferToolBundles, OfferToolProducts, TSEDashboard, PbpwList,
		TaxPayer, EstablishmentList, CreateEstablishment, EditEstablishment, TSSList, CreateTSS, EditTSS, TseClientsList, CreateClient, EditClient
	} = asyncComponents;

	const requiredPermissions = {
		usersModule: ['usersModule'],
		usersCreate: ['usersCreate'],
		usersUpdate: ['usersUpdate'],
		organizationsSuperAdminModule: ['organizationsSuperAdminModule'],
		organizationsSupportModule: ['organizationsSupportModule'],
		organizationsLicenseModule: ['organizationsLicenseModule'],
		organizationsIncomingLicenseModule: ['organizationsIncomingLicenseModule'],
		organizationsIncomingLicenseRead: ['organizationsIncomingLicenseRead'],
		customerLicenseModuleList: ['customerLicenseModuleList'],
		phoenixIncomingLicenseModule: ['phoenixIncomingLicenseModule'],
		phoenixIncomingLicenseRead: ['phoenixIncomingLicenseRead'],
		jumpbirdIncomingLicenseModule: ['jumpbirdIncomingLicenseModule'],
		jumpbirdCompaniesViewDetail: ['jumpbirdCompaniesViewDetail'],
		organizationsSuperAdminRead: ['organizationsSuperAdminRead'],
		organizationsSupportRead: ['organizationsSupportRead'],
		organizationsLicenseRead: ['organizationsLicenseRead'],
		customerLicenseMachineModule: ['customerLicenseMachineModule', 'customerPhoenixLicenseCompanyList'],
		organizationPaymentStatusDetail: ['organizationPaymentStatusDetail', 'organizationPaymentStatusDynamicFormFieldGenerate', 'organizationPaymentStatusFormFieldUpdate'],
		licensesIncoming: ['licensesIncoming'],
		licensesMachineList: ['licensesMachineList'],
		autoUpdateMachineList: ['autoUpdateMachineList'],
		incomingLicenseMachineDataUpdate: ['incomingLicenseMachineDataUpdate'],
		licensesLicenseNumbers: ['licensesLicenseNumbers'],
		orderCodeExtensionsList: ['orderCodeExtensionsList'],
		licenseModuleGetLicenseNumberDetail: ['licenseModuleGetLicenseNumberDetail'],
		reaList: ['reaList'],
		allPartnerReportList: ['allPartnerReportList'],
		partnerReportDetail: ['partnerReportDetail'],
		invoicesGroupsList: ['invoicesGroupsList'],
		invoicesList: ['invoicesList'],
		inventories: ['inventories'],
		taxRulesListTaxRules: ['taxRulesListTaxRules'],
		invoicesCustomersList: ['invoicesCustomersList'],
		turnoverStatistics: ['turnoverStatistics'],
		invoicesCustomersDocumentsList: ['invoicesCustomersDocumentsList'],
		listInventoryGroup: ['listInventoryGroup'],
		rolesModule: ['rolesModule'],
		permissionsModule: ['permissionsModule'],
		emailTemplateModule: ['emailTemplateModule'],
		emailTemplateEdit: ['emailTemplateEdit'],
		phoenixCompanyList: ['phoenixCompanyList'],
		phoenixCompanyRead: ['phoenixCompanyRead'],
		phoenixCompanyUpdate: ['phoenixCompanyUpdate'],
		phoenixModuleList: ['phoenixModuleList'],
		phoenixPackageList: ['phoenixPackageList'],
		phoenixApiKeyList: ['phoenixApiKeyList'],
		birdhubClientsList: ['birdhubClientsList'],
		birdhubOrdersList: ['birdhubOrdersList'],
		birdhubOrdersDetail: ['birdhubOrdersDetail'],
		jumpbirdCompaniesList: ['jumpbirdCompaniesList'],
		jumpbirdCompaniesUpdateCompanyData: ['jumpbirdCompaniesUpdateCompanyData'],
		jumpbirdSubscriptionsListPackages: ['jumpbirdSubscriptionsListPackages'],
		resigoAdminListMachines: ['resigoAdminListMachines'],
		resigoAdminViewMachineDetail: ['resigoAdminViewMachineDetail'],
		resigoAdminUpdateMachineData: ['resigoAdminUpdateMachineData'],
		resigoAdminListLicenses: ['resigoAdminListLicenses'],
		resigoAdminDecodeLicenseDetail: ['resigoAdminDecodeLicenseDetail'],
		tseDeviceList: ['tseDeviceList'],
		tseViewDeviceDetail: ['tseViewDeviceDetail', 'tseActivityLog'],
		tseCustomerList: ['tseCustomerList'],
		tseCustomerRead: ['tseCustomerRead'],
		tseCustomerListV2: ['tseCustomerListV2'],
		tseBackupUploadStatisticsGraphView: ['tseBackupUploadStatisticsGraphView', 'tseCustomerStatistics'],
		zmListGetRequests: ['zmListGetRequests'],
		pbpwList: ['pbpwGetCurrentAndNextHourPasswords', 'pbpwListPosbillPasswords', 'pbpwStore', 'pbpwGetUploadFileTempCredential', 'pbpwGetCurrentAndNextHourPasswordsOfCustomDate'],
		bundleGroup: ['offerToolListBundleGroups'],/* TODO: Change the permission */
		bundles: ['offerToolListBundles'], /* TODO: Change the permission */
		products: ['offerToolGetProducts'], /* TODO: Change the permission */
		createOrUpdateTaxPayer: ['tseRegisterCreateOrUpdateTaxpayer', 'tseRegisterGetTaxpayer'],
		establishmentList: ['tseRegisterListEstablishments', 'tseRegisterCreateEstablishment', 'tseRegisterUpdateEstablishment'],
		createEstablishment: ['tseRegisterCreateEstablishment'],
		updateEstablishment: ['tseRegisterUpdateEstablishment'],
		tssList: ['tseRegisterListTSS', 'tseRegisterCreateTSS', 'tseRegisterUpdateTSS'],
		createTSS: ['tseRegisterCreateTSS'],
		updateTSS: ['tseRegisterUpdateTSS'],
		clientList: ['tseRegisterListClients'],
		createClient: ['tseRegisterCreateClient'],
		updateClient: ['tseRegisterUpdateClient'],
	}
	if (isPublicPage || isPhoenixOnboarding || isPhoenixCompanyPasswordForget || isPhoenixCompanyPasswordReset) {

		return (
			<Routes>
				<Route path='/company/validate/:token' element={<CompanyVerification />} />
				<Route path='/phoenix/onboarding' element={<PhoenixOnboarding />} />
				<Route path='/phoenix/company/forget-password' element={<PhoenixForgetPassword />} />
				<Route path='/phoenix/company/reset-password/:token' element={<PhoenixResetPassword />} />
				<Route path="*" element={<Navigate replace to="/404" />} />
			</Routes>
		);
	} else if (isLoggedIn) {
		return (
			<Layout>
				<Suspense fallback={<Spinner />}>
					<Routes>
						<Route
							path="/"
							element={<Dashboard />} />

						{checkPermission({ permission: requiredPermissions.usersModule }) && (
							<Route
								path="/users/list" element={<Users />} />
						)}

						{checkPermission({ permission: requiredPermissions.usersCreate }) && (
							<Route
								path='/users/add'
								element={<AddUser />} />)}

						{checkPermission({ permission: requiredPermissions.usersUpdate }) && (
							<Route
								path='/users/edit/:id'
								element={<EditUser />} />)}

						{checkPermission({ permission: requiredPermissions.organizationsSuperAdminModule }) && (
							<Route
								path='/organizations/superAdmin/list'
								element={<SuperAdminOrganization />} />)}

						{checkPermission({ permission: requiredPermissions.organizationsSupportModule }) && (
							<Route
								path='/organizations/support/list'
								element={<SupportOrganization />} />)}

						{checkPermission({ permission: requiredPermissions.organizationsLicenseModule }) && (
							<Route
								path='/organizations/license/list'
								element={<LicenseOrganization />} />)}

						{checkPermission({ permission: requiredPermissions.organizationsIncomingLicenseModule }) && (
							<Route
								path='/organizations/incoming-license'
								element={<OrganizationIncomingLicenses />} />)}

						{checkPermission({ permission: requiredPermissions.organizationsIncomingLicenseRead }) && (
							<Route
								path='/organizations/incoming-license/:hardwareId'
								element={<OrganizationIncomingLicenseDetail />} />)}

						{checkPermission({ permission: requiredPermissions.customerLicenseModuleList }) && (
							<Route path='/licenses/module/list'
								element={<LicenseModuleList />} />)}

						{checkPermission({ permission: requiredPermissions.phoenixIncomingLicenseModule }) && (
							<Route
								path='/organizations/phoenix/incoming-license'
								element={<PhoenixIncomingLicenses />} />)}

						{checkPermission({ permission: requiredPermissions.phoenixIncomingLicenseRead }) && (
							<Route path='/organizations/phoenix/incoming-license/:id'
								element={<CompanyDetail />} />)}

						{checkPermission({ permission: requiredPermissions.jumpbirdIncomingLicenseModule }) && (
							< Route
								path='/organizations/jumpbird/incoming-license'
								element={<JumpbirdIncomingLicenses />} />)}

						{checkPermission({ permission: requiredPermissions.jumpbirdCompaniesViewDetail }) && (
							<Route
								path='/organizations/jumpbird/incoming-license/:id'
								element={<JumpbirdCompanyDetail />} />)}

						{checkPermission({ permission: requiredPermissions.organizationsSuperAdminRead }) && (
							<Route
								path='/organizations/superAdmin/:id'
								element={<SuperAdminOrgDetail />} />)}

						{checkPermission({ permission: requiredPermissions.organizationsSupportRead }) && (
							<Route
								path='/organizations/support/:custId'
								element={<CustomerLicense />} />)}

						{/* <RouteManager
							permission={['organizationsSupportRead']} exact path='/organizations/support/:id'
							component={<SupportAdminOrgDetail} />/> */}

						{checkPermission({ permission: requiredPermissions.organizationsLicenseRead }) && (
							<Route
								path='/organizations/license/:id'
								element={<LicenseAdminOrgDetail />} />)}

						{checkPermission({ anyOnePermission: requiredPermissions.customerLicenseMachineModule }) &&
							< Route
								path='/organizations/license/list/:custId'
								element={<CustomerLicense />} />}

						{checkPermission({ anyOnePermission: requiredPermissions.customerLicenseMachineModule }) &&
							<Route path='/organizations/list/:custId'
								element={<CustomerLicense />} />}

						{checkPermission({
							permission: requiredPermissions.organizationPaymentStatusDetail
						}) &&
							<Route path='/organizations/payment-status/:custId'
								element={< CustomerLicense />} />}

						{checkPermission({
							permission: requiredPermissions.licensesIncoming
						}) &&
							<Route
								path='/licenses/incoming'
								element={<IncomingLicenses />} />}
						{checkPermission({
							permission: requiredPermissions.licensesMachineList
						}) &&
							<Route path='/licenses/machine/list'
								element={<MachineList />} />}
						{checkPermission({
							permission: requiredPermissions.autoUpdateMachineList
						}) &&
							<Route path='/licenses/auto-update-machine/list'
								element={<AutoUpdateMachineList />} />}
						{checkPermission({
							permission: requiredPermissions.organizationsIncomingLicenseRead
						}) && <Route path='/licenses/machine/:hardwareId'
							element={<OrganizationIncomingLicenseDetail />} />}
						{checkPermission({
							permission: requiredPermissions.incomingLicenseMachineDataUpdate
						}) && <Route
								path='/licenses/machine/edit/:hardwareId'
								element={<OrganizationIncomingLicenseDetail />} />}
						{checkPermission({
							permission: requiredPermissions.licensesLicenseNumbers
						}) && <Route
								path='/licenses/license-numbers'
								element={<LicenseNumbers />} />}
						{checkPermission({
							permission: requiredPermissions.orderCodeExtensionsList
						}) && <Route
								path='/licenses/order-code-extension'
								element={<OrderCodeExtensions />} />}
						{checkPermission({
							permission: requiredPermissions.orderCodeExtensionsList
						}) && <Route
								path='/licenses/configurations'
								element={<ConfigurationsList />} />}

						{checkPermission({
							permission: requiredPermissions.licenseModuleGetLicenseNumberDetail
						}) && <Route
								path='/licenses/license-decoder'
								element={<LicenseDecoder />} />}

						{checkPermission({
							permission: requiredPermissions.reaList
						}) && <Route
								path='/accounting/rea/list'
								element={<ReaList />} />}

						{checkPermission({
							permission: requiredPermissions.allPartnerReportList
						}) && <Route
								path='/accounting/provision/reports/list'
								element={<ReportList />} />}
						{checkPermission({
							permission: requiredPermissions.partnerReportDetail
						}) && <Route
								path='/accounting/provision/report/:id'
								element={<ReportDetail />} />}

						{checkPermission({
							permission: requiredPermissions.invoicesGroupsList
						}) && <Route
								path='/accounting/invoices/list'
								element={<InvoiceGroupList />} />}
						{checkPermission({
							permission: requiredPermissions.invoicesList
						}) && <Route
								path='/accounting/invoices/:id'
								element={<InvoiceGroupDetail />} />}

						{checkPermission({
							permission: requiredPermissions.inventories
						}) && <Route
								path='/accounting/inventories/list'
								element={<InventoriesList />} />}

						{checkPermission({
							permission: requiredPermissions.taxRulesListTaxRules
						}) && <Route
								path='/accounting/tax-rules/list'
								element={<TaxRulesList />} />}

						{checkPermission({
							permission: requiredPermissions.invoicesCustomersList
						}) && <Route
								path='/accounting/customers/list'
								element={<CustomerList />} />}

						{checkPermission({
							permission: requiredPermissions.turnoverStatistics
						}) && <Route
								path='/accounting/turnover'
								element={<Turnover />} />}

						{checkPermission({
							permission: requiredPermissions.invoicesCustomersDocumentsList
						}) && <Route
								path='/accounting/customers/documents/:id'
								element={<DocumentList />} />}
						{/* <RouteManager
							permission={['invoicesList']} exact path='/accounting/invoices/:id'
							element={<InvoiceGroupDetail} />/> */}

						{checkPermission({
							permission: requiredPermissions.listInventoryGroup
						}) && < Route
								path='/accounting/inventory-group/list'
								element={< InventoryGroupList />} />}
						{/* <RouteManager
							permission={['invoicesList']} exact path='/accounting/invoices/:id'
							element={<InvoiceGroupDetail} />/> */}

						{checkPermission({
							permission: requiredPermissions.rolesModule
						}) && <Route
								path='/roles/list'
								element={<Roles />} />}
						{checkPermission({
							permission: requiredPermissions.permissionsModule
						}) && <Route
								path='/roles/permissions'
								element={<Permissions />} />}

						<Route path='/profile' element={<Profile />} />

						{/* <RouteManager
							permission={['birdhubCredentialList']} exact path='/birdhub/credential/list'
							element={<Credential} />/> */}

						{checkPermission({
							permission: requiredPermissions.phoenixCompanyList
						}) && < Route
								path='/phoenix/company/list'
								element={<Company />} />}
						{checkPermission({
							permission: requiredPermissions.phoenixCompanyRead
						}) && < Route
								path='/phoenix/company/:id'
								element={<CompanyDetail />} />}
						{checkPermission({
							permission: requiredPermissions.phoenixCompanyUpdate
						}) && < Route
								path='/phoenix/company/edit/:id'
								element={<CompanyDetail />} />}
						{checkPermission({
							permission: requiredPermissions.phoenixModuleList
						}) && < Route
								path='/phoenix/module/list'
								element={<ModuleList />} />}
						{checkPermission({
							permission: requiredPermissions.phoenixPackageList
						}) && < Route
								path='/phoenix/package/list'
								element={<PackageList />} />}
						{checkPermission({
							permission: requiredPermissions.phoenixApiKeyList
						}) && < Route
								path='/phoenix/api-key/list'
								element={<ApiKeyList />} />}

						{checkPermission({
							permission: requiredPermissions.birdhubClientsList
						}) && < Route
								path='/birdhub/clients/list'
								element={<ClientsList />} />}
						{checkPermission({
							permission: requiredPermissions.birdhubOrdersList
						}) && < Route
								path='/birdhub/clients/:client_code/orders'
								element={<OrdersList />} />}
						{checkPermission({
							permission: requiredPermissions.birdhubOrdersDetail
						}) && < Route
								path='/birdhub/clients/:client_code/orders/:id'
								element={<OrderDetail />} />}


						{checkPermission({
							permission: requiredPermissions.jumpbirdCompaniesList
						}) && < Route
								path='/jumpbird/company/list'
								element={<JumpbirdCompany />} />}
						{checkPermission({
							permission: requiredPermissions.jumpbirdCompaniesViewDetail
						}) && < Route
								path='/jumpbird/company/:id'
								element={<JumpbirdCompanyDetail />} />}
						{checkPermission({
							permission: requiredPermissions.jumpbirdCompaniesUpdateCompanyData
						}) && < Route
								path='/jumpbird/company/edit/:id'
								element={<JumpbirdCompanyDetail />} />}
						{checkPermission({
							permission: requiredPermissions.jumpbirdSubscriptionsListPackages
						}) && < Route
								path='/jumpbird/package/list'
								element={<JumpbirdPackageList />} />}

						{checkPermission({
							permission: requiredPermissions.resigoAdminListMachines
						}) && < Route
								path='/resigo/machine/list'
								element={<ResigoMachine />} />}
						{checkPermission({
							permission: requiredPermissions.resigoAdminViewMachineDetail
						}) && < Route
								path='/resigo/machine/:id'
								element={<ResigoMachineDetail />} />}
						{checkPermission({
							permission: requiredPermissions.resigoAdminUpdateMachineData
						}) && < Route
								path='/resigo/machine/edit/:id'
								element={<ResigoMachineDetail />} />}
						{checkPermission({
							permission: requiredPermissions.resigoAdminListLicenses
						}) && < Route
								path='/resigo/licenses/list'
								element={<ResigoLicense />} />}

						{checkPermission({
							permission: requiredPermissions.resigoAdminDecodeLicenseDetail
						}) && < Route
								path='/resigo/licenses/decoder'
								element={<ResigoLicenseDecoder />} />}

						{checkPermission({
							permission: requiredPermissions.tseDeviceList
						}) && < Route
								path='/tse/device/list'
								element={<TSEDeviceList />} />}
						{checkPermission({
							permission: requiredPermissions.tseViewDeviceDetail
						}) && < Route
								path='/tse/device/activity/:id'
								element={<TSEDeviceActivityLog />} />}
						{checkPermission({
							permission: requiredPermissions.tseCustomerList
						}) && < Route
								path='/tse/universe/customer/list'
								element={<TSECustomerList />} />}
						{checkPermission({
							permission: requiredPermissions.tseCustomerRead
						}) && < Route
								path='/tse/universe/customer/:id'
								element={<TSECustomerDetail />} />}
						{checkPermission({
							permission: requiredPermissions.tseCustomerListV2
						}) && < Route
								path='/tse/customer/list'
								element={<TSECustomerListV2 />} />}
						{checkPermission({
							permission: requiredPermissions.tseCustomerRead
						}) && < Route
								path='/tse/customer/:id'
								element={<TSECustomerDetail />} />}
						{checkPermission({ anyOnePermission: requiredPermissions.tseBackupUploadStatisticsGraphView })
							&& <Route path='/tse/dashboard' element={<TSEDashboard />} />
						}

						{checkPermission({
							permission: requiredPermissions.zmListGetRequests
						}) && < Route
								path='/tools/zm/list'
								element={<ZMList />} />}
						{checkPermission({
							permission: requiredPermissions.bundleGroup
						}) && < Route
								path='/offer-tool/bundleGroup'
								element={<OfferToolBundleGroup />} />}
						{checkPermission({
							permission: requiredPermissions.bundles
						}) && < Route
								path='/offer-tool/bundles'
								element={<OfferToolBundles />} />}
						{checkPermission({
							permission: requiredPermissions.products
						}) && < Route
								path='/offer-tool/products'
								element={<OfferToolProducts />} />}

						{checkPermission({ anyOnePermission: requiredPermissions.pbpwList })
							&& < Route
								path='/tools/pbpw'
								element={<PbpwList />} />}

						{checkPermission({ anyOnePermission: requiredPermissions.createOrUpdateTaxPayer })
							&& < Route
								path='fiskaly/taxpayer'
								element={<TaxPayer />} />}
						
						{checkPermission({ anyOnePermission: requiredPermissions.establishmentList })
							&& < Route
								path='fiskaly/establishments'
								element={<EstablishmentList />} />}

						{checkPermission({ anyOnePermission: requiredPermissions.createEstablishment })
							&& < Route
								path='fiskaly/establishment/create'
								element={<CreateEstablishment />} />}
						{checkPermission({ anyOnePermission: requiredPermissions.updateEstablishment })
							&& < Route
								path='fiskaly/establishment/:establishmentId'
								element={<EditEstablishment />} />}

						{checkPermission({ anyOnePermission: requiredPermissions.tssList })
							&& < Route
								path='fiskaly/tss'
								element={<TSSList />} />}

						{checkPermission({ anyOnePermission: requiredPermissions.createTSS })
							&& < Route
								path='fiskaly/tss/create'
								element={<CreateTSS />} />}

						{checkPermission({ anyOnePermission: requiredPermissions.updateTSS })
							&& < Route
								path='fiskaly/tss/:tssId'
								element={<EditTSS />} />}

						{checkPermission({ anyOnePermission: requiredPermissions.clientList })
							&& < Route
								path='fiskaly/establishment/:establishmentId/client'
								element={<TseClientsList />} />}

						{checkPermission({ anyOnePermission: requiredPermissions.createClient })
							&& < Route
								path='fiskaly/establishment/:establishmentId/client/create'
								element={<CreateClient />} />}

						{checkPermission({ anyOnePermission: requiredPermissions.updateClient })
							&& < Route
								path='fiskaly/establishment/:establishmentId/tss/:tssId/client/:clientId'
								element={<EditClient />} />}

						<Route path='/404' element={<NotFound />} />
						<Route path="/login" element={<Navigate to='/' />} />
						<Route path="*" element={<Navigate to='/404' />} />

					</Routes>
				</Suspense>
			</Layout>
		);
	} else {
		// lazy loading not needed for login, forget password, reset password APIs
		return (
			<Routes>
				<Route path='/login' element={<Login />} />
				<Route path='/forget-password' element={<ForgetPassword />} />
				<Route path='/reset-password/:token' element={<ResetPassword />} />
				{/* <Navigate to='/login' /> */}
				<Route path="*" element={<Navigate replace to="/login" />} />
			</Routes>
		);
	}
}

export default App;

