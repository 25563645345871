import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import * as Yup from 'yup';

import { PosBillPermissionsByModule } from 'features/permissions/interface/permissions.interface';
import { FieldConfig } from 'shared/components/form/inputTypes';
import { errorMessages } from 'shared/constants/messages';
import { PosBillRole } from 'features/roles/interface/roles.interface';
import { GetParameters } from 'features/organizations/orgIncomingLicenses/interfaces/orgIncomingLicenses.interface';

import { Action, State } from '../interface';
import localizationConstants from './translation/constants';
import i18n from './localization';
import { branchIds } from 'shared/constants/constants';
import { TSSInterface } from 'features/fiskaly/interface/tss';

export const createAction = (ACTION: string, data: any = null): Action => {
	return {
		type: ACTION,
		payload: data
	};
};

export const createLoadingSelector = (actions: string[]) => (state: State) => {
	// returns true only when all actions is not loading
	let loader = false;
	for (let i = 0; i < actions.length; i += 1) {
		if (state.loading.api[actions[i]]) {
			loader = true;
			break;
		}
	}
	return loader;
};

/**
 * get field onfiguration, and validation schema for adding-role
 * @param permissionsByModule permission list by module name
 * permissionsByModule contains all possible permissions that are present in system, with it's own module
 * this function returns number of checkbox input types same as number of modules
 * each checkbox input will contain checkbox options for permissions inside that module
 */
export const buildFieldConfigForRoleForm = (permissionsByModule: PosBillPermissionsByModule[]) => {
	const fieldConfiguration: FieldConfig[] = [
		{ type: 'text', label: localizationConstants.name, name: 'name' }
	];
	const fieldConfigSchema: any = {
		name: Yup.string().min(2, errorMessages.minLength(localizationConstants.name, 2))
			.required(errorMessages.required(localizationConstants.name)).strict(true),
	};
	permissionsByModule.forEach(permissionObj => {
		fieldConfiguration.push({
			type: 'checkbox',
			label: i18n.t(`permission-module-${permissionObj.moduleName}`),
			name: permissionObj.moduleName,
			isCollapsible: true,
			otherOptions: {
				checkboxOptions: permissionObj.permissions.map(p => {
					return { name: i18n.t(p.slug), value: p.id, translationKey: p.slug };
				})
			}
		});
		fieldConfigSchema[permissionObj.moduleName] = Yup.array();
	});
	return {
		fieldConfig: cloneDeep(fieldConfiguration),
		fieldConfigSchema: fieldConfigSchema
	};
};

export const getRoleDropDownOptions = (fieldConfig: FieldConfig, roles: PosBillRole[]) => {
	let roleDropDownOptions = ((fieldConfig.otherOptions || {}).dropDownOptions || []);
	if (!roleDropDownOptions.length || roles.length !== roleDropDownOptions.length) {
		roleDropDownOptions = [];
		roles.forEach(role => roleDropDownOptions.push({ name: role.name, value: role.name }));
		if (!fieldConfig.otherOptions) {
			fieldConfig.otherOptions = {};
		}
		fieldConfig.otherOptions.dropDownOptions = roleDropDownOptions;
	}
	return fieldConfig;
};

/**
 * function which returns formatted date
 * @param date
 */
export const formatDate = (date: any, format?: string) => {
	if (!date) {
		return '';
	}
	return moment(date).local().format(format || 'YYYY-MM-DD HH:mm:ss');
};


/**
 * function which returns local date and time
 * @param date
 */
export const convertUTCtoLocalDateAndTime = (date: any, format?: string) => {
	const language = localStorage.getItem('lang') || 'en';
	if (!date) {
		return '';
	}
	return moment.utc(date).local().locale(language).format(format || 'YYYY-MM-DD HH:mm:ss');
}

export const formatBytes = (bytes: any, decimals: number = 2) => {
	if (bytes === 0) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const isValidBranchId = (id: string | number) => {
	return branchIds.includes(id.toString());
};

export const debounce = (func: any, wait = 400) => {
	let h: NodeJS.Timeout;
	return (...args: any) => {
		clearTimeout(h);
		h = setTimeout(() => func(...args), wait);
	};
};

export const getIncomingLicenseQuery = (data: GetParameters) => {
	const query: any = { ...data.queryData };
	if (!query.orderByColumn) {
		delete query.orderByColumn;
		delete query.orderBy;
	}
	Object.keys(data.filters).forEach(key => {
		query[`filter[${key}]`] = data.filters[key];
	});
	return query;
};

export const getCamelCaseWord = (word: String) => {
	if (!!word) {
		const convertedWord = typeof word !== 'string' ? word.toString() : word;
		return convertedWord.charAt(0).toUpperCase() + convertedWord.slice(1).toLowerCase();
	} else return word;
}

export const currencyConverter = (language: string, amount: number) => Intl.NumberFormat(language, { minimumFractionDigits: 2 }).format(amount);

export const getLatestEightYearsList = () => {
	const currentYear = Number(moment().format('YYYY'));
	const years = [{ id: currentYear, label: currentYear }];

	for (let index = currentYear - 1; index > (currentYear - 8); index--) {
		years.push({ id: index, label: index });
	}

	return years;
}

export const getTSSDropDownOptions = (fieldConfig: FieldConfig, tssList: TSSInterface[]) => {
	let tssDropDownOptions = ((fieldConfig.otherOptions || {}).searchOptions || []);
	if (!tssDropDownOptions.length || tssList.length !== tssDropDownOptions.length) {
		tssDropDownOptions = [];
		tssList.forEach(tss => tssDropDownOptions.push({ value: tss.id ?? '', label: tss.id ?? '' }));
		if (!fieldConfig.otherOptions) {
			fieldConfig.otherOptions = {};
		}
		fieldConfig.otherOptions.searchOptions = tssDropDownOptions;
	}
	return fieldConfig;
};
