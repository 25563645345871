/* tslint:disable */
const de = {
	"dashboardTitle": "Dashboard",
	"autoGeneratedLicenseGraphTitle": "Automatisch generierte Lizenz",
	"upcomingCreatedLicenseGraphTitle": "Insgesamt erstellte Lizenz",
	"daily": "täglich",
	"weekly": "wöchentlich",
	"monthly": "monatlich",
	"today": "Heute",
	"yesterday": "Gestern",
	"thisWeek": "Diese Woche",
	"lastWeek": "Letzte Woche",
	"thisMonth": "Diesen Monat",
	"lastMonth": "Im vergangenen Monat",
	"thisYear": "Dieses Jahr",
	"lastYear": "Vergangenes Jahr",
	"custom": "Datum wählen (Brauch)",
	"backBtnText": "Zurück",
	"organizationsTitle": "Organisationen",
	"superAdmin": "superAdmin",
	"organizationAlreadyExist": "Organisation existiert bereits",
	"license": "Lizenz",
	"support": "Support",
	"licenses": "Lizenzen",
	"posbillAdmin": "Posbill-Admin",
	"incomingLicensesTitle": "Lizenzanfragen",
	"machineListTitle": "Maschinenliste",
	"excludeDebAutoId": "DEB-Auto-IDs ausschließen",
	"enterDebAutoIds": "DEB-Auto-IDs eingeben",
	"excludeCustomerId": "Kundennummern ausschließen",
	"enterCustomerIds": "Kundennummern eingeben",
	"excludedIdsList": "Liste der ausgeschlossenen IDs",
	"excludeFilterPlaceholder": "XXXXX ⏎",
	"excludeFilterNote": "Drücken Sie die Eingabetaste (⏎) oder die Leertaste, um das Tag zu erstellen.",
	"excludeFilterErrorMessage": "Die eingegebene ID ist bereits ausgeschlossen, bitte geben Sie eine andere ID ein.",
	"addedRemovedTagMessage": "Sie haben eine ID eingegeben, die Sie gerade zum Entfernen markiert haben, sodass diese ID jetzt nicht entfernt wird.",
	"undoRemove": "Vorgang zum Entfernen rückgängig machen",
	"remove": "Entfernen",
	"excludedId": "Ausgeschlossene ID",
	"toBeRemoveId": "Zu entfernende ID",
	"goingToExcludeId": "ID wird ausgeschlossen",
	"excludeFilter": "Filter ausschließen",
	"autoUpdateMachineTitle": "Automatisches Update der Maschine",
	"licenseNumberView": "Lizenznummer anzeigen",
	"orderCodeExtensions": "Bestellcode-Erweiterungen",
	"orderCodeExtensionsList": "Bestellcode-Erweiterungsliste",
	"configurations": "Konfigurationen",
	"configurationsList": "Konfigurationsliste",
	"editConfigurationValue": "Konfigurationswert bearbeiten",
	"licenseDecoder": "Lizenzdekodierer",
	"posBillLicenseDecoder": "PosBill Lizenzdekodierer",
	"order_code_id": "id",
	"order_code_used": "wird eingesetzt?",
	"order_code_used_at": "verwendet bei",
	"users": "Benutzer",
	"user": "Benutzer",
	"list": "liste",
	"create": "erstellen",
	"yesCreateAndSendMail": "JA, E-MAIL ERSTELLEN UND SENDEN",
	"createAndSendMail": "e-mail erstellen und senden",
	"createAndOpenInSevDesk": "Create and open in sevDesk",
	"yesCreate": "JA, ERSTELLEN",
	"yesReCreate": "JA, NEU ERSTELLEN",
	"rolesAndPermissions": "Rollen & Berechtigungen",
	"roles": "Rollen",
	"role": "Rolle",
	"status": "Status",
	"permissions": "Berechtigungen",
	"addUser": "Nutzer hinzufügen",
	"supportList": "Support-Liste",
	"licenseList": "Lizenzliste",
	"superAdminList": "super-admin-liste",
	"logout": "Ausloggen",
	"home": "Hauptmenü",
	"view": "Ansicht",
	"createLicense": "Lizenz erstellen",
	"delete": "Löschen",
	"restore": "Wiederherstellen",
	"edit": "Bearbeiten",
	"save": "sparen",
	"all": "Alles",
	"select": "Auswählen",
	"customer": "Kunde",
	"Customer": "Kunde",
	"partner": "Partner",
	"Partner": "Partner",
	"customers": "Kunden",
	"customerList": "Kundenliste",
	"leads": "Führt",
	"search": "Suche",
	"licenseNumbers": "Lizenznummern",
	"licenseInformation": "Lizenzinformationen",
	"historyLogOfMachineFor": "Verlaufsprotokoll der Maschine für",
	"historyLogOfLicenseFor": "Verlaufsprotokoll der Lizenz für",
	"unassigned": "Nicht zugewiesen",
	"organizationDetails": "Organisationsdetails",
	"profile": "Profil",
	"editUser": "Benutzer bearbeiten",
	"notFoundMsg": "Es tut uns leid, aber die von dir gesuchte Seite wurde nicht gefunden. Überprüfe die URL auf Fehler und klicke dann in deinem Browser auf die Schaltfläche Aktualisieren oder versuche etwas anderes in unserer App zu finden.",
	"pageNotFound": "Seite nicht gefunden",
	"actions": "Aktionen",
	"orgTimeline": "Organisationszeitleiste",
	"noDataFoundText": "Keine Daten gefunden",
	"serialNumber": "Nr.",
	"name": "Name",
	"customer_org_name": "Name",
	"code": "Code",
	"taxRate": "Steuersatz",
	"taxSet": "Steuersatz",
	"taxRateName": "Name des Steuersatzes",
	"country": "Land",
	"update": "Aktualisieren",
	"email": "Email",
	"recipientEmail": "Empfänger E-Mail",
	"senderEmail": "Absender E-Mail",
	"password": "Passwort",
	"phoenixPassword": "Bitte lege dein Passwort fest",
	"phoenixPasswordSuccessfullyReset": "Passwort erfolgreich zurückgesetzt!",
	"oldPassword": "Altes Passwort",
	"newPassword": 'Neues Kennwort',
	"getPassword": "Password bekommen",
	"confirmPassword": "Passwort bestätigen",
	'nextPassword': "Nächstes Passwort",
	'currentPassword': 'Aktuelles Passwort',
	"passwordConfirmation": "Passwort Bestätigung",
	"createdAt": "Erstellt am",
	"updatedAt": "Aktualisiert am",
	"functions": "Funktionen",
	"hardwareKey": "Hardwarekey",
	"noOfRecords": "Anzahl der Maschinen",
	"noOfRecordsMinimumValidationMessage": "Der Wert muss größer als 0 (Null) sein",
	"hw_id": "Hardware ID",
	"licenseNumber": "Lizenznummer",
	"expiryDateType": "Art des Ablaufdatums",
	"week": "Woche",
	"weeks": "Wochen",
	"year": "Jahr",
	"month": "Monat",
	"months": "Monate",
	"typeValue": "Typ Wert",
	"value": "Wert",
	"nextButton": "Weiter",
	"expiryDate": "Ablaufdatum",
	"autoLicense": "Autolizenz",
	"version": "Ausführung",
	"timestampInsert": "Eingefügt um",
	"timestampUpdate": "Aktualisiert am",
	"activeLicense": "Aktive Lizenz",
	"nextLicense": "Aktive Lizenz",
	"posbillVersion": "PosBill Version",
	"address": "Adresse",
	"postal_code": "Postleitzahl",
	"update_able": "aktualisierbar",
	"version_2": "Version 2",
	"sql_server": "SQL Server",
	"beauty": "Beauty",
	"boutique": "Boutique",
	"friseur": "Friseur",
	"direktverkauf": "Direktverkauf",
	"baeckerei": "Bäckerei",
	"kiosk": "Kiosk",
	"hospitality": "Gastro",
	"retail": "Verkauf",
	"fun_version": "Fun Version",
	"change_of_days": "Wechsel der Tage",
	"pb_p_version": "PB P Version",
	"db_version": "DB Version",
	"is_locked": "Ist gesperrt",
	"hashcode": "Hash-Code",
	"auto_license": "Autolizenz",
	"auto_update": "Auto-Update",
	"windows_version": "Windows Version",
	"mpos": "mpos",
	"wawi": "WaWi",
	"order_code": "Bestellcode",
	"configuration_name": "Konfigurationsname",
	"configuration_value": "Konfigurationswert",
	"timestamp_insert": "Zeitstempel einfügen",
	"timestamp_update": "Zeitstempel-Update",
	"partner_id": "Partner-ID",
	"swn_name": "SWN Name",
	"swn_surname": "SWN Familienname",
	"sepa_name": "SEPA Name",
	"sepa_surname": "SEPA Nachname",
	"bank_account_name": "Kontoname",
	"bank_name": "Bank Name",
	"bank_iban": "Bank IBAN",
	"iban": "IBAN",
	"amount": "Betragen",
	"software": "Software",
	"bank_bic": "Bank BIC",
	"autoUpdate": "Automatisches Update",
	"autoUpdateOn": "Schalten Sie die automatische Aktualisierung ein",
	"autoUpdateOff": "Automatisches Update deaktivieren",
	"validFrom": "Gültig ab",
	"validTo": "Gültig bis",
	"newLicense": "Neue Lizenz",
	"manageModules": "Module verwalten",
	"viewModules": "Module anzeigen",
	"UnableToFindAnActiveLicense": 'Es kann keine aktive Lizenz finden',
	"lock": "Sperren",
	"locked": "Verschlossen",
	"unlock": "Freischalten",
	"unlocked": "Entsperrt",
	"licenseLock": "Lizenzsperre",
	"licenseUnlock": "Lizenz entsperren",
	"machineLock": "Maschinenschloss",
	"machineUnlock": "Maschine entsperren",
	"licenseLocked": "Lizenznummer gesperrt",
	"licenseUnlocked": "Lizenznummer freigeschaltet",
	"machineLocked": "Maschine gesperrt",
	"machineUnlocked": "Maschine entsperrt",
	"machineAutoLicenseEnabled": "Automatische Computerlizenz aktiviert",
	"machineAutoLicenseDisabled": "Automatische Computerlizenz deaktiviert",
	"sendLicenseEmail": "Lizenz-E-Mail senden",
	"generate": "Generieren",
	"copyEmailAndPasswordToClipboard": "E-Mail und Passwort in die Zwischenablage kopieren",
	"submit": "Speichern",
	"submitWithEmail": "Mit E-Mail senden",
	"cancel": "Abbrechen",
	"yes": "Ja",
	"no": "Nein",
	"on": "Auf",
	"off": "Aus",
	"archived": "archiviert",
	"archive": "Archiv",
	"active": "aktiv",
	"Active": "Aktiv",
	"addNewUser": "Neuen Benutzer hinzufügen",
	"createTaxRule": "Neue Steuerregel erstellen",
	"id": "Id",
	"module": "Modul",
	"close": "Schließen",
	"noUsersAvailable": "Keine Benutzer verfügbar",
	"slug": "Schnecke",
	"language": "Sprache",
	"german": "Deutsch",
	"english": "Englisch",
	"noPermissionsAvailable": "Keine Berechtigungen verfügbar",
	"changePassword": "Passwort ändern",
	"updateProfileTitle": "Profil Aktualisieren",
	"changePasswordTitle": "Ändere Dein Passwort",
	"editUserDetails": "Benutzerdetails bearbeiten",
	"newRegisteredUser": "Neuer Benutzer registrieren",
	"inActive": "inaktiv",
	"blocked": "Gesperrt",
	"incomingLicenseList": "Lizenzanfragen",
	"licenseNumbersList": "Liste der Lizenznummern",
	"forgetPassword": "Passwort vergessen",
	"getPasswordResetCode": "Email senden",
	"resetInstructionsAreSentToYourEmail": "Wir haben eine Nachricht an die angegebene Email-Adresse gesendet. Dort findest Du einen Link um das Passwort zurückzusetzen.",
	"backToLogin": "Zurück zur Anmeldung",
	"noMachinesFound": "Keine Maschinen gefunden",
	"resetYourPassword": "Passwort zurücksetzen",
	"resetPassword": "Passwort zurücksetzen",
	"generatePassword": "Generiere Passwort",
	"login": "Anmeldung",
	"hello": "Hallo",
	"licenseDetailsAreHere": "Deine Lizenzdetails für PosBill findest du hier",
	"thankYou": "Danke",
	"yourPosbillTeam": "Dein PosBill-Team",
	"licenseReport": "Lizenzbericht",
	"standard": "Standard",
	"premium": "Premium",
	"demo": "Demo",
	"fun": "Fun",
	"default": "Standard",
	"sql": "SQL",
	"addItem": "Artikel hinzufügen",
	"item": "Artikel",
	"OK": "okay",
	"inventoryGroupName": "Name der Inventargruppe",
	"addLicense": "Lizenz hinzufügen",
	"addHardware": "Hardware hinzufügen",
	"add": "Hinzufügen",
	"addWithEmail": "Mit E-Mail hinzufügen",
	"send": "senden",
	"openEmail": "E-Mail öffnen",
	"addNewRole": "Neue Rolle hinzufügen",
	"editRole": "Rolle bearbeiten",
	"customerData": "Kundendaten",
	"name1": "Name 1",
	"name2": "Name 2",
	"street": "Straße",
	"zipCode": "Postleitzahl",
	"city": "Stadt",
	"nation": "Nation",
	"phone": "Telefon",
	"fax": "Fax",
	"ust": "Ust",
	"expiryDateValidate": "Bitte wähle das Ablaufdatum",
	"Activate Auto License": "Autolizenz aktivieren",
	"to": "an",
	"cc": "CC",
	"subject": "Betreff",
	"content": "Inhalt",
	"displayInInvoiceCreation": "Ändern Sie den Status",
	"inventoryGroupStatus": "NventoryGroupStatuss",
	"youDontHavePermission": "Sie haben keine Erlaubnis",
	"requiredFieldValidation": "{{field}} ist erforderlich",
	"fieldSelectionValidation": "Bitte {{field}} auswählen",
	"exactLengthValidation": "{{field}} muss genau {{length}} Zeichen haben!",
	"minLengthStrValidation": "{{field}} muss länger als {{length}} Zeichen sein!",
	"minLengthArrValidation": "{{field}} muss mindestens {{length}} Elemente enthalten!",
	"maxLengthStrValidation": "{{field}} muss kürzer als {{length}} Zeichen sein!",
	"maxLengthArrValidation": "{{field}} darf maximal {{length}} artikel enthalten!",
	"minValueValidation": "{{field}} muss größer oder gleich {{value}} sein!",
	"maxValueValidation": "{{field}} muss kleiner oder gleich {{value}}!",
	"typeValidation": "{{field}} muss eine gültige {{type}} sein!",
	"hardwareKeyValidation": "Der {{field}} darf nur Ziffern enthalten (darf nicht mit 0 beginnen)",
	"passwordValidation": "Das Passwort muss mindestens 8 Zeichen enthalten, einschließlich eines Großbuchstabens, eines Kleinbuchstaben und einer Zahl sowie eines Sonderzeichens. Es darf keine Leerzeichen enthalten",
	"passwordMatchValidation": "Passwörter stimmen nicht überein",
	"amountValidation": "{{field}} muss ein gültiger Wert sein!",
	"confirmRestoreMsg": "Möchtest du den {{field}} wirklich wiederherstellen?",
	"confirmDeleteMsg": "Möchtest du den {{field}} wirklich entfernen?",
	"confirmLogoutMsg": "Möchtest du dich wirklich abmelden?",
	"confirmAutoLicense": "Möchtest du den Status der automatischen Lizenz für eine bestimmte Lizenz wirklich aktualisieren?",
	"confirmChangeLicenseVersion": "Möchtest du die Lizenzversion wirklich ändern?",
	"confirmChangeLockStatus": "Möchtest du den Lizenzsperrstatus wirklich ändern?",
	"confirmChangeMachineLockStatus": "Möchtest du den Status der Maschinensperre wirklich ändern?",
	"confirmMachineAutoLicense": "Möchtest du den Status der automatischen Computerlizenz wirklich ändern?",
	"noLicensesFound": "Keine Lizenzen gefunden",
	"noOrderCodeExtensionFound": "Keine Bestellcode-Erweiterung gefunden",
	"noApiKeyFound": "Kein API-Schlüssel gefunden",
	"noTaxRuleFound": "Name des Steuersatzes",
	"noExpiredLicensesFound": "Keine abgelaufenen Lizenzen gefunden",
	"addHardwareNotAllowed": "Du kannst für diese Organisation keine Hardware hinzufügen, da die Kunden-ID nicht gültig ist",
	"customerIdRequirements": "(Das Feld Kundennummer muss eine Zahl sein und zwischen 1 und 99999 liegen.)",
	"givenCustomerIdIsNotValid": "Kunden-ID - {{customerId}} ist ungülti",
	"relateMachineToCustomer": "Maschine dem Kunden zuordnen",
	"relateCompanyToCustomer": "Unternehmen mit Kunden in Verbindung bringen",
	"newCustomer": "Neukunde",
	"customerId": "Kundennummer",
	"customerIds": "Kundennummern",
	"customer_id": "Kundennummer",
	"copied": "Kopiert",
	"copyText": "Text Kopieren",
	"difference": "Unterschied",
	"relate": "zuordnen",
	"relateAndJumpToCustomer": "Verknüpfe und springe zum Kunden",
	"details": "Einzelheiten",
	"totalRecords": "Gesamtzahl der Datensätze",
	"apply": "Anwenden",
	"canNotCreateCustomerMsg": "Du kannst keinen neuen Kunden erstellen.",
	"next": "Nächste",
	"previous": "früher",
	"perPage": "Pro Seite",
	"paid": "Bezahlt",
	"unpaid": "Unbezahlt",
	"manual": "Manuell",
	"invalid": "Geplatzt",

	"internal": "intern",
	"global": "global",
	"local": "lokal",

	"birdhubAdmin": "birdhubAdmin",
	"birdhubAdminTitle": "Birdhub Admin",
	"credentials": "Referenzen",
	"credentialList": "Anmeldeinformationsliste",
	"addNewCredential": "Neuen Berechtigungsnachweis hinzufügen",
	"addCredential": "Anmeldeinformationen hinzufügen",
	"updateCredential": "Anmeldeinformationen aktualisieren",
	"credentialInfo": "Anmeldeinformationen",
	"orderDetail": "Bestelldetails",
	"ordersList": "Bestellliste",
	"passwordsList": "Kennwörterliste",
	"orderItems": "Auftragspositionen",
	"order_id": "Auftragsnummer",
	"customer_title": "Kunde",
	"order_date": "Auftragsdatum",
	"shop_code": "Shop-Code",
	"total_discount": "Totaler Rabatt",
	"total_gross": "Gesamtbrutto",
	"total_net": "Gesamtnetto",
	"total_shipping_cost": "Gesamtversandkosten",
	"total_vat": "Gesamt MwSt",
	"item_id": "Id",
	"item_text": "Artikel",
	"single_price": "Stückpreis",
	"total_price": "Gesamtpreis",
	"new": "Neu",
	"dispatched": "Versendet",
	"delivered": "Geliefert",
	"refused": "Abgelehnt",
	"cancelled": "Abgesagt",
	"clients": "Kunden",

	"apiId": "API-ID",
	"apiSecretKey": "Geheimer Api-Schlüssel",
	"type": "Art",
	"note": "Hinweis",
	"notes": "Anmerkungen",

	"phoenixAdmin": "phoenixAdmin",
	"phoenixAdminTitle": "Phoenix Admin",
	"companyList": "Firmenliste",
	"companyDetail": "Firmendetail",
	"companyEdit": "Firma bearbeiten",
	"companyId": "Firmen-ID",
	"markAsCreated": "Als erstellt markieren",
	"markAsComplete": "Als abgeschlossen markieren",
	"transactionId": "Transaktions-ID",
	"phoenixIncomingLicenseList": "Phoenix Eingehende Lizenzliste",
	"phoenixIncomingLicenseTitle": "Phoenix Incoming-Lizenzen",
	"isEmailVerified": "Ist die E-Mail-Adresse bestätigt?",
	"emailVerifiedAt": "E-Mail bestätigt um",
	"verified": "verifiziert",
	"priceInInventoryGroup": "Preis in Inventargruppe",
	"priceNotInInventoryGroup": "Preis nicht in Inventargruppe",
	"pending": "steht aus",
	"posbill": "Posbill",
	"phoenix": "Phoenix",
	"accepted": "Akzeptiert",
	"rejected": "Hat abgelehnt",
	"completed": "Abgeschlossen",
	"failed": "Gescheitert",
	"added": "Hinzugefügt",
	"notAdded": "Nicht hinzugefügt",
	"subscriptions": "Abonnements",
	"subscriptionId": "Abonnement-ID",
	"additionalUsers": "Zusätzliche Benutzer",
	"additionalFeatures": "Zusatzfunktionen",
	"bankInfo": "Bank Info",
	"universeId": "Universe-ID",
	"companyName": "Name der Firma",
	"isVerified": "isVerified",
	"unlimited": "Unbegrenzt",
	"setUnlimited": "Unbegrenzt einstellen",
	"manageSubscription": "Abonnement verwalten",
	"resetMasterPassword": "Master-Passwort zurücksetzen",
	"manageExpiryDate": "Ablaufdatum verwalten",
	"addTaxRule": "Steuerregel hinzufügen",
	"editTaxRule": "Steuerregel bearbeiten",
	"manageUser": "Benutzer verwalten",
	"companyLocked": "Firma gesperrt",
	"companyUnlocked": "Unternehmen freigeschaltet",
	"confirmChangeCompanyLockStatus": "Möchtest du den Sperrstatus der Firma wirklich ändern?",
	"confirmChangeJumpbirdCompanyStatus": "Möchten Sie den Unternehmensstatus wirklich ändern?",
	"confirmCancelSubscription": "Möchten Sie das Abonnement wirklich kündigen?",
	"confirmSetPhoenixLicenseDateUnlimited": "Möchtest du das Ablaufdatum der Lizenz wirklich auf unbegrenzt setzen? (01-01-1970)?",
	"confirmChangeActiveLicense": "Möchtest du diese Lizenz wirklich als aktiv festlegen?",
	"confirmDeleteLicense": "Möchtest du diese Lizenz wirklich löschen?",
	"confirmGetValidLicense": "Möchtest du wirklich gültige Lizenzen für diese Hardware erhalten?",
	"confirmResetPassword": "Möchtest du das Passwort wirklich zurücksetzen?",
	"confirmGeneratePassword": "Möchtest du sicher ein Passwort generieren?",
	"companySubscriptionEmptyMessage": "Es gibt kein Abonnement. Bitte wähle ein Abonnement, bevor du die Module auswählst.",
	"getValidLicense": "gültige Lizenz erhalten",
	"confirmChangeUsedOrderCodeStatus": "Möchten Sie den Status des Bestellcodes wirklich ändern?",

	"modules": "Module",
	"moduleList": "Modulliste",
	"addNewModule": "Neues Modul hinzufügen",
	"addModule": "Modul hinzufügen",
	"updateModule": "Modul aktualisieren",
	"price": "Preis",

	"package": "Paket",
	"packages": "Pakete",
	"packageList": "Paketliste",
	"updatePackage": "Paket aktualisieren",
	"phoenixId": "Phoenix Id",
	"description": "Beschreibung",

	"apiKeyList": "API-Schlüsselliste",
	"defaultUserCount": "Standardbenutzer",
	"currentDefaultUsers": "Aktuelle Standardbenutzer",
	"currentTotalUsers": "Aktuelle Benutzer insgesamt",
	"newDefaultUsers": "Neue Standardbenutzer",
	"newTotalUsers": "Neue Benutzer insgesamt",

	"jumpbirdIncomingLicenseList": "Jumpbird Eingehende Lizenzliste",
	"jumpbirdIncomingLicenseTitle": "Jumpbird Incoming-Lizenzen",
	"agb_gdpdr": "AGB & Datenschutz",
	"sepa_accepted_at": "Wird SEPA akzeptiert?",
	"licenseInfo": "Lizenz Info",
	"packageInfo": "Paket Info",
	"companies": "Firmen",
	"company_name": "Company Name",
	"is_advertising": "Is Advertising?",
	"contact_person_first_name": "Contact Person First Name",
	"contact_person_last_name": "Contact Person Last Name",
	"registration_date": "Registration Date",
	"updated_at": "Updated At",
	"onboard_from": "OnBoard From",
	"terms_conditions_url": "Terms Conditions URL",
	"package_code": "Package Code",
	"start_date": "Start Date",
	"end_date": "End Date",
	"subscription_status": "Subscription Status",
	"bank_account_owner_name": "Bank Account Owner Name",
	"tax_id": "Tax Id",
	// "iban": "IBAN",
	"bic": "BIC",
	"website": "Website",
	"block_reason": "Sperrgrund",
	"paymentMethod": "Bezahlverfahren",
	"manageSepa": "SEPA verwalten",
	"accept": "Annehmen",
	"reject": "Ablehnen",
	"reason": "Grund",
	"rejectReasonLabel": "Schreiben Sie einen Ablehnungsgrund.",
	"blockReasonLabel": "Schreiben Sie einen Grund für die Sperrung des Unternehmens.",
	"cancelSubscriptionReasonLabel": "Schreiben Sie einen Grund für die Kündigung des Abonnements.",
	"companyBlocked": "Unternehmen gesperrt",
	"companyUnblocked": "Unternehmen entsperrt",
	"searchByCompanyNameOrEmail": "Suche nach Firmennamen oder E-Mail",
	"cancelSubscription": "Abonnement kündigen",
	"nex_billing_date": "Nächstes Abrechnungsdatum",
	"assignPackage": "Abonnement zuweisen",
	"bankAccountOwnerName": "Name des Bankkontoinhabers",
	"billingCycles": "Abrechnungszyklen",
	"inventoryGroupNotExist": "Die Inventargruppe existiert nicht",

	"jumpbirdAdmin": "jumpbirdAdmin",
	"jumpbirdAdminTitle": "Jumpbird Admin",

	"tse": "TSE",
	"tseSerialUpload": "TSE Serial Upload",
	"tseDevice": "TSE-Gerät",
	"tseDeviceDetail": "TSE-Gerätedetail",
	"tseDeviceList": "TSE-Geräteliste",
	"importCSV": "CSV importieren",
	"deviceFile": "Gerätedatei",
	"file": "Datei",
	"upload": "Hochladen",
	"supportedType": "Unterstützter Typ",
	"downloadSampleFile": "Beispieldatei herunterladen",
	"activate": "aktivieren Sie",
	"confirmDeviceActivateMsg": "Möchtest du die Maschine wirklich aktivieren?",
	"date": "Datum",
	"deviceDetail": "Gerätedetail",
	"fieldName": "Feldname",
	"changedValue": "Geänderter Wert",
	"originalValue": "Original Wert",
	"activityLogs": "Aktivitätsprotokolle",
	"adminPin": "Admin Pin",
	"admin_pin": "Admin Pin",
	"adminPuk": "Admin Puk",
	"admin_puk": "Admin Puk",
	"timeAdminPin": "Zeit Admin Pin",
	"time_admin_pin": "Zeit Admin Pin",
	"timeAdminPuk": "Zeit Admin Puk",
	"time_admin_puk": "Zeit Admin Puk",
	"app": "App",
	"publicKey": "Öffentlicher Schlüssel",
	"public_key": "Öffentlicher Schlüssel",
	"secretKey": "Geheimer Schlüssel",
	"secret_key": "Geheimer Schlüssel",
	"activatedOn": "Aktiviert am",
	"activated_on": "Aktiviert am",
	"expiredAt": "Abgelaufen bei",
	"expired_at": "Abgelaufen bei",
	"uploadCount": "Upload Count",
	"tseDashboardTitle": "TSE-Dashboard",
	"tseBackupUpload": "TSE Backup Upload",
	"universeCustomers": "Universe-Kunden",
	"universeCustomer": "Universe-Kunde",
	"universeCustomerList": "Universe-Kundenliste",
	"tseCustomers": "TSE-Kunden",
	"tseCustomer": "TSE-Kunde",
	"tseCustomerList": "TSE Kundenliste",
	"tseCustomerDetail": "TSE Kundendetail",
	"backupStatistics": "Sicherungsstatistik",
	"portalAccount": "Portal-Konto",
	"backupCount": "Backup-Anzahl",
	"inProgressUpload": "In Bearbeitung Hochladen",
	"successfulUpload": "Erfolgreiches Hochladen",
	"failedUpload": "Upload fehlgeschlagen",
	"firstUploadAt": "Erstes Upload-Datum",
	"lastUploadAt": "Letztes Upload-Datum",
	"backupList": "Sicherungsliste",
	"debAutoId": "DEB-Auto",
	"suggested": "Vorgeschlagene",
	"fileName": "Dateiname",
	"fileSize": "Dateigröße",
	"serial_number": "Ordnungsnummer",
	"checksum": "Prüfsumme",
	"workingDate": "Arbeitsdatum",
	"expiringDate": "Ablaufdatum",
	"progressing": "Fortschritt",
	"inProgress": "In Bearbeitung",
	"uploaded": "Hochgeladen",
	"downloaded": "Heruntergeladen",
	"createDownloadRequest": "Download-Anfrage erstellen",
	"activeBackupData": "Aktive Sicherungsdaten",
	"backupInProgressText": "Dein Backup wird ausgeführt ...",
	"backupReadyText": "Dein Backup ist fertig",
	"backupFiles": "Sicherungs-Dateien",
	"generateDownloadLink": "Download-Link generieren",
	"generatedLinkText": "Der Backup-Link wird generiert. Klicke auf den Download-Button",
	"downloadBackup": "Backup herunterladen",
	"confirmCreateDownloadRequest": "Wenn du eine neue Sicherung anforderst, wird die alte heruntergeladene Sicherung entfernt. Möchtest du wirklich fortfahren?",
	"addNewCustomer": "Neuen Kunden hinzufügen",
	"createCustomerPortalAccount": "Erstellen Sie ein Kundenportalkonto",
	"sendMailText": "Bestätigungs-E-Mail an den Kunden senden?",
	"sendMailNoteText": "Wenn nein, wird das Kundenkonto automatisch verifiziert.",

	"tools": "Werkzeuge",
	"offerTool": "Angebotstool",
	"bundleGroup": "Bundle-Gruppe",
	"createNewBundleGroup": "Neue Bundle-Gruppe erstellen",
	"updateBundleGroup": "Bundle-Gruppe aktualisieren",
	"bundle": "Bündeln",
	"bundles": "Bündel",
	"createNewBundle": "Neues Paket erstellen",
	"updateBundle": "Update-Paket",
	"frontendPositionName": "Name der Frontend-Position",
	"frontendId": "Frontend-ID",
	"color": "Farbe",
	"image": "Bild",
	"red": "Rot",
	"green": "Grün",
	"blue": "Blau",
	"visibility": "Sichtweite",
	"products": "Produkte",
	"createNewProduct": "Neues Produkt erstellen",
	"updateProduct": "Produkt aktualisieren",
	"shortName": "Kurzbezeichnung",
	"offerName": "Angebotsname",
	"multiplierSelection": "Multiplikatorauswahl",
	"product_description": "Produktbeschreibung",
	"offerDescription": "Angebotsbeschreibung",
	"onlyAvailableInAt": "Nur in Österreich verfügbar?",
	"onlyAvailableInDe": "Nur in Deutschland verfügbar?",
	"netPrice": "Nettopreis",
	"purchaseOrLeasingFlag": "Kauf- oder Leasingkennzeichen vorhanden?",
	"purchasePrice": "Kaufpreis",
	"isSalesPriceEditable": "Ist Verkaufspreis editierbar?",
	"variantProductPriceOption": "Variantenproduktpreisoption vorhanden?",
	"assignProductsToBundle": "Produkte dem Bundle zuordnen",
	"assignBundlesToBundleGroup": "Bündel einer Bündelgruppe zuordnen",
	"allBundles": "Alle Pakete",
	"bundleListOfBundleGroup": "Paketliste der Paketgruppe",
	"allProducts": "Alle Produkte",
	"productListOfBundle": "Produktliste des Pakets",
	"manageBundleGroup": "Bundle-Gruppe verwalten",
	"manageBundle": "Bundle verwalten",
	"manageProduct": "Bundle Produkte",
	"uploadImage": "Bild hochladen",

	"zmList": "ZM-Liste",
	"pbpw": "Pbpw",
	"selectFile": "Datei aussuchen",
	"csvUpload": "Hochladen der ZMList-Eingabedatei",
	"passwordCsvUpload": "Kennwort hochladen CSV",
	"inputCloudPath": "Cloud-Pfad eingeben",
	"processing": "Wird bearbeitet",
	"processed": "Verarbeitet",
	"requestDownload": "Download anfordern",
	"refresh": "Aktualisieren",
	"download": "Herunterladen",
	"please_select_csv_file": "Bitte CSV-Datei auswählen.",
	"please_select_valid_csv_file": "Bitte wählen Sie eine gültige CSV-Datei aus.",
	"please_select_valid_file": "Bitte wählen Sie eine gültige Datei aus.",

	"emailVerification": "E-Mail-Verifizierung",
	"verifyingEmail": "E-Mail überprüfen ...",
	"successfulEmailVerified": "E-Mail erfolgreich verifiziert!",
	"companyCreation": "Unternehmensgründung",
	"creatingCompany": "Unternehmen gründen ...",
	"startPhoenixNow": "Starte jetzt mit Phoenix",
	"redirectToPhoenix": "Gehe zum Phoenix Portal",
	"companyAlreadyValidatedMessage": "Deine Firma ist schon eingerichtet! Klicke auf den Link um dich einzuloggen",
	"companyVerificationSuccessMessage": "Deine Anmeldung war erfolgreich!",
	"companyVerificationFailMessage": "Es tut uns leid. Deine Firma konnte nicht verifiziert werden!",
	"companyVerificationTimeoutMessage": "Das erstellen deiner Firma dauert unerwartet ein wenig länger, du kannst dich aber gleich unter folgendem Link einloggen",

	"fetchDataText": "Filter anwenden, um Daten abzurufen",
	"confirmChangeAutoUpdateMachineStatus": "Möchtest du den Computer wirklich automatisch aktualisieren?",
	"filter": "Filter",
	"selectFilterOptionText": "Filteroption auswählen",
	"filterSelectedText": "Filter ausgewählt",
	"noFilterSelected": "Kein Filter ausgewählt",
	"pbpVersion": "PB P Version",
	"dbVersion": "DB Version",

	"enable": "Aktivieren",
	"disable": "Deaktivieren",
	"enabled": "Aktiviert",
	"disabled": "Deaktiviert",

	"resigoAdmin": "resigoAdmin",
	"resigoAdminTitle": "Resigo-Admin",
	"machineList": "Maschinenliste",
	"basic": "Basic",
	"profi": "Profi",
	"profiPlus": "Profi Plus",
	"privateKey": "Privat Schlüssel",
	"roomCount": "Zimmeranzahl",
	"resigoLicenseDecoder": "Resigo Lizenzdekodierer",

	"gep": "GEP",
	"bez": "BEZ",
	"changes": "Änderungen",
	"posbillGEP": "PosBill GEP",
	"posbillBEZ": "PosBill BEZ",
	"phoenixGEP": "Phoenix GEP",
	"phoenixBEZ": "Phoenix BEZ",
	"blockAllLicenses": "Alle Lizenzen blockieren",
	"unblockAllLicenses": "Alle Lizenzen entsperren",
	"blockAllCompany": "Alle Unternehmen blockieren",
	"unblockAllCompany": "Alle Unternehmen entsperren",
	"key": "Schlüssel",
	"oldValue": "Alter Wert",
	"newValue": "Neuer Wert",
	"same": "gleich",
	"changed": "geändert",
	"confirmFieldSubmitMsg": "Bist du sicher, dass du die Formularfelddaten senden möchtest?",
	"confirmDeleteTaxRule": "Möchten Sie die Steuerregel wirklich löschen?",
	"sendEmail": "E-Mail senden",
	"reset": "Neutral",
	"doNothing": "Nichts tun",

	"no_label": "Kein Etikett",
	"non_customer": "Nicht Kunde",
	"non_partner": "Nicht Partner",
	"vip": "VIP",
	"non_vip": "Nicht VIP",

	"none": "Keiner",
	"showMore": "Zeig mehr",
	"showLess": "Zeige weniger",

	"provision": "Bereitstellung",
	"modProvision": "Mod. Provision", // Client asked not to translate to German
	"module_provision_percentage": "Mod. Provision (%)", // Client asked not to translate to German
	"pos": "POS",
	"pos_amount": "POS (€)",
	"posProvision": "POS Provision", // Client asked not to translate to German
	"pos_provision_percentage": "POS Provision (%)", // Client asked not to translate to German
	"accounting": "Buchhaltung",
	"invoices": "Rechnungen",
	// "allInvoiceGroups": "Liste aller Rechnungsgruppen",
	// "activeInvoiceGroups": "Aktive Rechnungsgruppenliste",
	// "archivedInvoiceGroups": "Archivierte Rechnungsgruppenliste",
	"invoice": "Rechnung",
	"reaList": "REA-Liste",
	"turnoverStatistics": "Umsatzstatistik",
	"turnover": "Umsatz",
	"turnoverCategory": "Kategorie",
	"turnoverCategoryId": "Kategorie-Id",
	"turnoverNet": "Umsatz (netto)",
	"turnoverName": "Produktname",
	"turnoverQuantity": "Anzahl",
	"turnoverPriceNet": "Preis (netto)",
	"inventories": "Lagerbestände",
	"inventoryGroup": "Inventargruppe",
	"invoiceGroupList": "Rechnungsgruppenliste",
	"inventoryGroupList": "Inventargruppen Liste",
	"inventoriesList": "Inventarliste",
	"addInventoryGroup": "Inventargruppe hinzufügen",
	"removeInvoice": "Rechnung entfernen",
	"downloadInvoicePDF": "Rechnung PDF herunterladen",
	"taxRules": "Steuerregeln",
	"documents": "Unterlagen",
	"documentList": "Dokumentenliste",
	"sevDeskNotLinked": "SevDesk ist noch nicht mit diesem Partner verbunden.",
	"sevDeskNotInitiated": "SevDesk ist noch nicht gestartet.",
	"sevDeskTaxRules": "SevDesk-Steuerregeln",
	"createNote": "Notiz erstellen",
	"createInvoice": "Rechnung erstellen",
	"generateInvoicePDF": "Rechnungs-PDF generieren",
	"createInventoriyGroup": "Inventargruppe erstellen",
	"updateInventoriyGroup": "Inventargruppe aktualisieren",
	"invoiceWithoutTax": "Rechnung ohne MwSt",
	"reCreateNote": "Notiz neu erstellen",
	"creditNote": "Credit Note", // Client asked not to translate to German
	"periodOfService": "Leistungszeitraum",
	"serviceDuration": "Dienstdauer",
	"bankRetrievalDate": "Bankabrufdatum",
	"expand": "Expandieren",
	"expandAll": "Alle erweitern",
	"collapse": "Zusammenbruch",
	"collapseAll": "Alles ausblenden",
	"hits": "Treffer",
	"hitsLabel": "Label-Treffer",
	"hitsKey": "Key-Treffer",
	"noteAlreadyCreated": "Hinweis bereits erstellt",
	"invoiceAlreadyCreated": "Rechnung bereits erstellt",
	"reportList": "Berichtsliste",
	"generateReport": "Bericht generieren",
	"generateRea": "REA-Liste generieren",
	"bulkCreateCreditNote": "Bulk-Gutschrift erstellen",
	"creatingCreditNotes": "Gutschriften erstellen",
	"loading": "Wird geladen",
	"quarter": "Quartal",
	"reportDetail": "Detail melden",
	"exportCSV": "CSV exportieren",
	"exportType": "Typ",
	"exportCSVComplete": "CSV (komplett)",
	"exportCSVFiltered": "CSV (gefiltert)",
	"exportXLSXFiltered": "XLSX (gefiltert)",
	"exportAll": "Alle",
	"exportOnlyActives": "Nur aktive",
	"manageProvisionReportExport": "Provision Report Export exportieren",
	"tradingDate": "Handelsdatum",
	"partnerList": "Partnerliste",
	"partnerId": "Partner ID",
	"contractBeginDate": "Contract Begin Date", // Client asked not to translate to German
	"contract_begin_date": "Contract Begin Date", // Client asked not to translate to German
	"provisionModuleAmount": "Modules",
	"module_amount": "Modules (€)",
	"reportCustomerId": "Customer ID", // Client asked not to translate to German
	"provisionW": "Provision W", // Client asked not to translate to German
	"provision_w_amount": "Provision W", // Client asked not to translate to German
	"provision_w_pd": "Provision W PD", // Client asked not to translate to German
	"totalMonths": "Total Months", // Client asked not to translate to German
	"total_months": "Total Months",// Client asked not to translate to German
	"totalCommission": "Total Commission", // Client asked not to translate to German
	"total_commission": "Total Commission (€)", // Client asked not to translate to German
	"reportActions": "Action",	 // Client asked not to translate to German
	"sum": "Summe",
	"orderBy": "Sortieren nach",
	"orderByColumn": "Nach Spalte sortieren",
	"partner_deb_auto": "Partner-ID",
	"partner_org_name": "Name der Partnerorganisation",
	"total_amount": "Gesamtmenge",
	"currentTotal": "Aktuelle Summe",
	"netAmount": "Netto-Betrag",
	"invoiceAmount": "Erwarteter Betrag",
	"remaining": "Verblieben",
	"salesTax": "Mehrwertsteuer",
	"tax_amount": "Steuerbetrag",
	"inventoriesNotAvailable": "Lagerbestände sind nicht vorhanden.",
	"amountMatchedMessage": "Gesamtbetrag stimmt mit erwartetem Betrag überein",
	"amountNotMatchedMessage": "Gesamtbetrag stimmt nicht mit dem erwarteten Betrag überein",
	"asc": "Aufsteigend",
	"desc": "Absteigend",
	"commissionStatus": "Provisionsstatus",
	"commissionCalculationStatus": "Provisionsberechnungsstatus",
	"reportStatus": "Berichtsstatus",
	"confirmDeletePartnerCommission": "Möchtest du die Partnerprovision wirklich entfernen?",
	"confirmExportPartnerCSV": "Möchten Sie den Partnerbericht wirklich exportieren?",
	"confirmDeletePartnerCreditNote": "Möchten Sie die Partnergutschrift wirklich entfernen?",
	"confirmDeleteCustomerCommission": "Möchtest du die Kundenprovision wirklich entfernen?",
	"confirmRevertCustomerCommission": "Möchten Sie die Kundenprovision wirklich zurücksetzen?",
	"confirmRevertCustomerCommissionStatus": "Möchten Sie den Provisionsstatus des Kunden wirklich zurücksetzen?",
	"confirmRevertModPercentage": "Möchten Sie den MOD-Prozentsatz des Kunden wirklich zurücksetzen?",
	"confirmRevertPosPercentage": "Möchten Sie den POS-Prozentsatz des Kunden wirklich zurücksetzen?",
	"confirmCreateNoteMessage": "Möchten Sie wirklich eine Notiz erstellen?",
	"confirmCreateInvoiceMessage": "Möchten Sie wirklich eine Rechnung erstellen?",
	"confirmCreateInvoiceAndSendMailMessage": "Sind Sie sicher, dass Sie eine Rechnung erstellen und per E-Mail versenden möchten?",
	"confirmDeleteInvoiceMessage": "Möchten Sie die Rechnung wirklich entfernen?",
	"confirmDeleteInventoryGroupMessage": "Möchten Sie die Inventargruppe wirklich entfernen?",
	"confirmArchiveInvoiceGroupMessage": "Möchten Sie diese Rechnungsgruppe wirklich archivieren?",
	"confirmRestoreInvoiceGroupMessage": "Möchten Sie diese Rechnungsgruppe wirklich wiederherstellen?",
	"confirmArchiveProvisionReportMessage": "Möchten Sie diesen Bereitstellungsbericht wirklich archivieren?",
	"confirmRestoreProvisionReportMessage": "Möchten Sie diesen Bereitstellungsbericht wirklich wiederherstellen?",
	"confirmReCreateNoteMessage": "Möchten Sie die Notiz wirklich neu erstellen?",
	"addCustomerCommission": "Kundenprovision hinzufügen",
	"searchCustomer": "Kunden suchen",
	"product": "Produkt",
	"quantity": "Menge",
	"autoCompleteSearchText": "Gib mindestens 3 Zeichen ein, um zu suchen ...",
	"noCustomerFound": "Kein Kunde gefunden",
	"somethingWentWrong": "Etwas ist schief gelaufen. Bitte versuche es erneut!!",

	"not_allowed": " nicht erlaubt",
	"pwd_s_amount_lowercase": "mind. 1 Kleinbuchstabe",
	"pwd_p_amount_lowercase": "mind. {{count}} Kleinbuchstaben",
	"pwd_s_amount_uppercase": "mind. 1 Großbuchstabe",
	"pwd_p_amount_uppercase": "mind. {{count}} Großbuchstaben",
	"pwd_s_amount_numbers": "mind. 1 Zahl",
	"pwd_p_amount_numbers": "mind. {{count}} Zahlen",
	"pwd_s_amount_special_characters": "mind. 1 Sonderzeichen",
	"pwd_p_amount_special_characters": "mind. {{count}} Sonderzeichen",
	"pwd_s_string_length": "1 oder mehr Zeichen",
	"pwd_p_string_length": "{{count}} oder mehr Zeichen",

	"licenseModule": "Lizenzmodul",
	"licenseModuleList": "Liste der Lizenzmodule",
	"classPosbill": "Klasse PosBill",
	"modType": "Mod-Typ",
	"regular": "Regulär",
	"countable": "Zählbar",

	"noFileSelectedErrorMessage": "Bitte wählen Sie mindestens eine Datei aus",
	"selectValidFileErrorMessage": "Bitte wählen Sie gültige CSV-Dateien aus.",

	"HardwareAdded": "neue Hardware hinzugefügt",
	"AssignedCustomer": "hat einem Kunden eine Maschine zugewiesen",
	"MachineUpdated": "hat eine Maschine aktualisiert",
	"PaymentStatusUpdated": "hat einen Zahlungsstatus aktualisiert",
	"LicenseGenerated": "Lizenz generiert",
	"LicenseActivated": "Lizenz aktiviert",
	"LicenseInactivated": "Lizenz deaktiviert",
	"LicenseAdded": "hat eine Lizenz hinzugefügt",
	"LicenseUpdated": "hat eine Lizenz aktualisiert",
	"LicenseDeleted": "hat eine Lizenz gelöscht",
	"LicenseEmailSent": "hat Lizenzdetails per E-Mail gesendet",
	"FutureLicenseActivated": "hat eine zukünftige Lizenz aktiviert",
	"ModuleUpdated": "hat ein Modul aktualisiert",
	"HardwareCloned": "Hardware wurde geklont",
	"LicenseCloned": "Lizenz wurde geklont",

	"january": "Januar",
	"february": "Februar",
	"march": "Marsch",
	"april": "April",
	"may": "Kann",
	"june": "Juni",
	"july": "Juli",
	"august": "August",
	"september": "September",
	"october": "Oktober",
	"november": "November",
	"december": "Dezember",

	"dateFrom": "Von",
	"dateTo": "Bis",
	"dateLastMonth": "Letzter Monat",
	"dateLastQuarter": "Letztes Quartal",
	"dateCurrentMonth": "Aktueller Monat",
	"dateCurrentQuarter": "Aktuelles Quartal",

	"quarter_end_date": "Enddatum des Quartals",
	"quarter_start_date": "Startdatum des Quartals",
	"created_by": "Erstellt von",
	"created_at": "Erstellt am",
	"editCustomerPercentages": "Bearbeiten Sie den POS-Prozentsatz oder MOD-Prozentsatz des Kunden",
	"decodeLicense": "Lizenz dekodieren",
	"decodeAnotherLicense": "Dekodieren Sie eine andere Lizenz",

	"permission-module-Organizations": "Organisationen",
	"permission-module-Widgets": "Widgets",
	"permission-module-Roles": "Rollen",
	"permission-module-Users": "Benutzer",
	"permission-module-EmailTemplates": "E-Mail-Vorlagen",
	"permission-module-EmailTemplateVariables": "E-Mail-Vorlagenvariablen",
	"permission-module-Permissions": "Berechtigungen",
	"permission-module-Licenses": "Lizenzen",
	"permission-module-CustomerLicense": "Kundenlizenz",
	"permission-module-Graphs": "Graph",
	"permission-module-BirdHubCredentials": "Birdhub-Anmeldeinformationen",
	"permission-module-PhoenixModules": "Phoenix-Module",
	"permission-module-PhoenixCompanies": "Phoenix Unternehmen",
	"permission-module-CustomerPhoenixLicense": "Phoenix Kundenlizenzen",
	"permission-module-PhoenixPackages": "Phoenix-Pakete",
	"permission-module-Invoices": "Rechnungen",
	"permission-module-TaxRules": "Steuerregeln",
	"permission-module-SevDesk": "SevDesk",
	"permission-module-HistoryLogs": "Verlaufsprotokolle",
	"permission-module-CustomerLicenses": "Kundenlizenzen",
	"permission-module-TSE": "TSE",
	"permission-module-PartnerProvision": "Partnerbereitstellung",
	"permission-module-UniverseCustomer": "Universe-Kunde",
	"permission-module-TseAdmin": "TSE-Administrator",
	"permission-module-LicenseAdmin": "Lizenzadministrator",
	"permission-module-ZMListTool": "ZM-Listen-Tool",
	"permission-module-PhoenixBranches": "Phoenix-Filialen",
	"permission-module-PhoenixApiKeys": "Phoenix API-Schlüssel",
	"permission-module-JumpbirdCustomerLicense": "Jumpbird-Kundenlizenz",
	"permission-module-JumpbirdCompanies": "Jumpbird-Unternehmen",
	"permission-module-JumpbirdSubscription": "Jumpbird-Abonnement",
	"permission-module-BirdHubMarketPlace": "BirdHub-Marktplatz",
	"permission-module-ResigoCustomerLicense": "Resigo-Kundenlizenz",
	"permission-module-resigoAdmin": "Resigo-Admin",
	"permission-module-PBPW": "PBPW",
	"permission-module-CustomerInvoice": "Kundenrechnung",
	"permission-module-PosBillBackup": "PosBill-Backup",
	"permission-module-Documents": "Unterlagen",
	"permission-module-OfferTool": "Angebotstool",
	"permission-module-TseRegister": "Fiskaly",

	"organizations.superAdminIndex": "Super Admin Liste",
	"organizations.licenseIndex": "Lizenzliste",
	"organizations.supportIndex": "Support-Liste",
	"organizations.show": "Organisation anzeigen",
	"organizations.timeline": "Zeitleiste der Organisation anzeigen",
	"organizations.superAdminShow": "Super Admin Detailansicht",
	"organizations.licenseShow": "Lizenz-Detailansicht",
	"organizations.supportShow": "Support-Detailansicht",
	"organizations.supportLicenseOrganizationDetail": "Details zur Support-Lizenzorganisation",
	"support.customerLicense.machineList": "Kundenlizenz-Maschinenliste abrufen",
	"support.customerLicense.machineLicenses": "Maschinenlizenzen für Kundenlizenzen erhalten",
	"organizations.metaData": "Metadaten der Organisation",
	"organizations.metaData.get": "Rufen Sie die Metadaten der Organisation ab",
	"incomingLicense.incomingRequestList": "Eingehende Lizenzanfragen",
	"incomingLicense.incomingRequest.show": "Eingehende Lizenzanforderung anzeigen",
	"incomingLicense.incomingAssignCustomer": "Bestehenden Kunden der Maschine zuordnen",
	"incomingLicense.createNewCustomer": "Erstelle einen neuen Kunden und weise ihn der Maschine zu",
	"widget.userStatistics": "Statistik-Widgets",
	"roles.index": "Alle Rollen anzeigen",
	"roles.store": "Rolle erstellen",
	"roles.show": "Rolle anzeigen",
	"roles.update": "Rolle aktualisieren",
	"roles.destroy": "Rolle löschen",
	"users.index": "Alle Benutzer anzeigen",
	"users.store": "Benutzer erstellen",
	"users.archive": "Liste der Archivbenutzer anzeigen",
	"users.show": "Benutzerdetails anzeigen",
	"users.update": "Benutzer aktualisieren",
	"users.destroy": "Benutzer löschen",
	"users.restore": "Benutzer wiederherstellen",
	"emailTemplate.index": "Alle E-Mail-Vorlagen anzeigen",
	"emailTemplate.store": "E-Mail-Vorlage erstellen",
	"emailTemplate.show": "E-Mail-Vorlage anzeigen",
	"emailTemplate.update": "E-Mail-Vorlage aktualisieren",
	"emailTemplate.destroy": "E-Mail-Vorlage löschen",
	"emailTemplateVariable.store": "E-Mail-Vorlagenvariable erstellen",
	"emailTemplateVariable.destroy": "E-Mail-Vorlagenvariable löschen",
	"permissions.index": "Alle Berechtigungen anzeigen",
	"licenses.incoming": "Eingehende Lizenzen",
	"licenses.machine_list": "Maschinenliste",
	"licenses.machine_customers": "licenses.machine_customers",
	"licenses.autoUpdateMachineList": "Automatische Aktualisierung der Maschinenliste",
	"licenses.autoUpdateMachine": "Automatisches Update der Maschine",
	"licenses.configuration.list": "Konfigurationsliste",
	"licenses.configuration.detail": "Details zur Konfiguration abrufen",
	"licenses.configuration.update": "Konfiguration aktualisieren",
	"licenses.license_numbers": "Lizenznummern",
	"customerLicense.machineIndex": "Kundenmaschinen",
	"customerLicense.machineStore": "Kundenrechner erstellen",
	"customerLicense.machineShow": "Kundencomputer anzeigen",
	"customerLicense.machineUpdate": "Kundenrechner aktualisieren",
	"customerLicense.machineLicenses": "Maschinenlizenzen",
	"customerLicenses.machineActiveLicense": "Maschine aktive Lizenz",
	"customerLicense.futureLicenseActivate": "Aktiviere die zukünftige Lizenz",
	"incomingLicense.updateMachineData": "Maschinendaten aktualisieren",
	"customerLicense.moduleList": "Module verwalten",
	"customerLicense.moduleStore": "Modul speichern",
	"customerLicense.moduleUpdate": "Modul-Update",
	"customerLicense.deleteLicense": "Lizenz löschen",
	"customerLicense.licenseStore": "Kundenlizenz erstellen",
	"customerLicense.licenseUpdate": "Kundenlizenz-Update",
	"customerLicense.sendEmail": "Lizenz-E-Mail senden",
	"graphs.autoGeneratedLicenseGraph": "Automatisch generiertes Lizenzdiagramm anzeigen",
	"graphs.upcomingCreatedLicenses": "Zeigt das Diagramm der anstehenden erstellten Lizenzen an",

	"order-code-extensions.list": "Bestellcode-Erweiterungsliste",
	"order-code-extensions.change-status": "Status der Bestellcode-Erweiterung ändern",

	"licenseModule.store": "Lizenzmodul erstellen",
	"licenseModule.update": "Lizenzmodul aktualisieren",
	"licenseModule.changeStatus": "Ändere den Status des Lizenzmoduls",
	"licenseModule.getLicenseNumberDetail": "Holen Sie sich Lizenznummer Details",

	"bird_hub.credentials.index": "Alle Birdhub-Anmeldeinformationen anzeigen",
	"bird_hub.credentials.show": "Sieh dir den Birdhub-Berechtigungsnachweis an",
	"bird_hub.credentials.store": "Erstelle einen Birdhub-Berechtigungsnachweis",
	"bird_hub.credentials.update": "Aktualisiere den Birdhub-Berechtigungsnachweis",

	"phoenix.incomingLicenseRequestList": "Phoenix Incoming Lizenzanfragen",
	"phoenix.incomingLicenseRequest.show": "Phoenix Incoming Lizenzanfrage anzeigen",
	"phoenix.incomingAssignCustomer": "Phoenix Bestehenden Kunden der Maschine zuordnen",
	"phoenix.incomingLicenseRequest.setUnlimitedExpiryDate": "Phoenix eingehende Lizenz Unbegrenztes Ablaufdatum festlegen",
	"phoenix.createNewCustomer": "Phoenix Erstellen und Zuweisen eines neuen Kunden zur Maschine",
	"phoenix.modules.index": "Alle Phoenix-Module anzeigen",
	"phoenix.modules.store": "Erstelle das Phoenix-Modul",
	"phoenix.modules.update": "Aktualisiere das Phoenix-Modul",
	"phoenix.modules.destroy": "Lösche das Phoenix-Modul",
	"phoenixModules.create-new-module": "Erstellen Sie ein neues The Phoenix-Modul",
	"phoenixModules.update-module": "Aktualisieren Sie das Phoenix-Modul",
	"phoenixModules.change-module-status": "Ändern Sie den Status des Phoenix-Moduls",
	"phoenix.companies.index": "Alle Unternehmen in Phoenix anzeigen",
	"phoenix.companies.show": "Sieh dir die Phoenix Company an",
	"phoenix.companies.update": "Phoenix Unternehmensdaten aktualisieren",
	"phoenix.companies.requestPassword": "Anforderung zum Zurücksetzen oder Generieren eines Passworts",
	"phoenix.subscriptions.index": "Alle Abonnements von Phoenix Company anzeigen",
	"phoenix.subscriptions.show": "Abonnement der Phoenix Company anzeigen",
	"phoenix.companies.requestResetPassword": "Anfrage zum Zurücksetzen des Passworts von Phoenix Company senden",
	"phoenix.branches.index": "Liste der Zweige von Phoenix",

	"birdhub.orders.list": "Liste der BirdHub-Bestellungen",
	"birdhub.orders.detail": "Birdhub-Bestelldetails",
	"birdhub.clients.list": "Liste der BirdHub-Clients",

	"phoenix.packages.index": "Alle Phoenix-Pakete anzeigen",
	"phoenix.packages.update": "Aktualisiere die Phoenix-Pakete",
	"phoenix.packages.update-status": "Status der Phoenix-Pakete aktualisieren",

	"phoenix.api-key.list": "Alle Phoenix API-Schlüssel anzeigen",
	"phoenix.api-key.generate": "Neuen Phoenix API-Schlüssel generieren",

	"jumpbird.incomingCompanyRequestList": "Jumpbird Incoming Lizenzanfragen",
	"jumpbird.incomingCompanyRequest.show": "Jumpbird Incoming Lizenzanfrage anzeigen",
	"jumpbird.incomingCompanyRequest.assignCustomer": "Jumpbird Bestehenden Kunden der Maschine zuordnen",
	"jumpbird.incomingCompanyRequest.createNewCustomer": "Jumpbird Erstellen und Zuweisen eines neuen Kunden zur Maschine",
	"jumpbirdCustomerLicense.listCompanies": "Alle Unternehmen eines Kunden auflisten",
	"jumpbirdCustomerLicense.updateSubscription": "Jumpbird Incoming-Lizenzabonnement aktualisieren",
	"jumpbird.companies.list": "Alle Unternehmen in Jumpbird anzeigen",
	"jumpbird.companies.viewDetail": "Sieh dir die Jumpbird Company an",
	"jumpbird.companies.updateCompanyData": "Jumpbird Unternehmensdaten aktualisieren",
	"jumpbird.subscriptions.listPackages": "Alle Abonnements von Jumpbird Company anzeigen",
	"jumpbirdCustomerLicense.manage-sepa": "Jumpbird SEPA-Abonnement für Jumpbird Company verwalten",
	"jumpbirdCustomerLicense.changeBlockStatus": "Jumpbird-Firmenstatus ändern (Blockieren | Entsperren)",
	"jumpbirdCustomerLicense.assign-package": "Kunden mit KOSTENLOSEM SEPA-Paket zuweisen | DEMO-Abonnement.",
	"jumpbirdCustomerLicense.cancel-subscription": "Kündigen Sie das Abonnement der Jumpbird Company.",
	"jumpbirdCustomerLicense.setSubscriptionPackageEndDate": "Ablaufdatum des Abonnements der Jumpbird Company festlegen",

	//-----------------------------------------Resigo--------------------------------------------

	//Resigo Machine
	"resigoCustomerLicense.listMachines": "Resigo-Kundenmaschinenliste",
	"resigoCustomerLicense.addMachine": "Neue Maschine zu Resigo-Kunde hinzufügen",
	"resigoCustomerLicense.updateMachine": "Aktualisieren Sie den Resigo-Kundencomputer",

	//Resigo License
	"resigoCustomerLicense.addMachineLicense": "Neue Lizenz zu Resigo Machine hinzufügen",
	"resigoCustomerLicense.listMachineLicenses": "Lizenzliste der Resigo-Kundenmaschine",
	"resigoCustomerLicense.updateLicense": "Aktualisieren Sie die Lizenz von Resigo Machine",
	"resigoCustomerLicense.deleteLicense": "Löschen Sie die Lizenz von Resigo Machine",
	"resigoCustomerLicense.sendLicenseEmail": "Senden Sie eine E-Mail mit der Lizenz von Resigo Machine",

	//Resigo Admin Machine
	"resigoAdmin.listMachines": "Zeigen Sie die Resigo Admin-Rechnerliste an",
	"resigoAdmin.updateMachineData": "Aktualisieren Sie die Resigo Admin-Rechnerdetails",
	"resigoAdmin.viewMachineDetail": "Details zum Resigo Admin-Computer anzeigen",

	//Resigo Admin License
	'resigoAdmin.listLicenses': "Zeigen Sie die Lizenzliste der Resigo Admin-Maschine an",
	'resigoAdmin.decodeLicenseDetail': "Lizenzdetails entschlüsseln",

	"tse.deviceList": "Alle TSE-Geräte anzeigen",
	"tse.createDevice": "TSE-Gerät erstellen",
	"tse.viewDeviceDetail": "TSE-Gerätedetails anzeigen",
	"tse.activityLog": "TSE-Geräteaktivitätsprotokoll",
	"tse.importDevice": "TSE-Geräte importieren",
	"tse.activateDevice": "TSE-Gerät aktivieren",
	"tseAdmin.uploadStatistics": "TSE Upload Statistics Graph anzeigen",
	"tseAdmin.customerStatistics": "TSE-Kundenstatistikdiagramm anzeigen",
	"universeCustomer.customers": "Alle Universe-Kunden anzeigen",
	"universeCustomer.customerDetail": "TSE-Kundendetails anzeigen",
	"tseAdmin.customers": "Alle TSE-Kunden anzeigen",
	"tseAdmin.backupStatistics": "Anzeigen der TSE-Kundensicherungsstatistik",
	"tseAdmin.backupHistory": "TSE-Sicherungsverlaufsliste anzeigen",
	"tseAdmin.downloadRequest": "Erstelle eine TSE Backup-Download-Anfrage",
	"tseAdmin.latestDownloadRequest": "Hol dir die neuesten Sicherungsdaten",
	"tseAdmin.get-tse-customer-detail": "Rufen Sie TSE-Kundendetails ab",
	"tseAdmin.downloadBackup": "Lade das TSE Backup herunter",
	"universeCustomer.customerRegistration": "Neuen TSE-Kunden hinzufügen",
	"universeCustomer.changeCustomerPassword": "Ändern Sie das Passwort für Universe-Kunden",

	"zmList.getRequests": "Alle ZM-Dateien anzeigen",
	"zmList.addRequest": "Anforderung zum Hochladen der ZM-Datei",
	"zmList.getRequestStatus": "Status der ZM-Datei abrufen",
	"zmList.requestDownloadOutputFile": "Anforderung zum Herunterladen der Ausgabe-ZM-Datei",
	"zmList.getUploadFileTempCredential": "Temporäre Anmeldeinformationen zum Hochladen von ZM-Dateien abrufen",

	"pbpw.getCurrentAndNextHourPasswords": "Holen Sie sich aktuelles und nächstes Passwort",
	"pbpw.listPosbillPasswords": "Passwortliste anzeigen",
	"pbpw.storePasswords": "Anfrage für Aktualisierungsliste",
	"pbpw.getUploadFileTempCredential": "Holen Sie sich Anmeldeinformationen für die Upload -Datei",
	"pbpw.getCurrentAndNextHourPasswordsOfCustomDate": "Holen Sie sich aktuelles und nächstes Passwort nach benutzerdefiniertem Datum",

	"organizations.payment_status.detail": "Details zum Zahlungsstatus der Organisation anzeigen",
	"organizations.payment_status.edit": "Generieren des Zahlungsstatus der Organisation Dynamische Formulardaten",
	"organizations.payment_status.update": "Aktualisieren des Zahlungsstatus der Organisation Dynamische Formulardaten",
	"organizations.payment_status.sendEmail": "Zahlungsstatus der Organisation E-Mail senden",
	"organizations.getOrganizationDetail": "Holen Sie sich die Organisationsdetails",

	"partnerProvision.list-all-provision-reports": "Liste aller Partnerberichte anzeigen",
	"partnerProvision.list-active-provision-reports": "Zeigen Sie die Liste der aktiven Partnerberichte an",
	"partnerProvision.list-archived-provision-reports": "Zeigen Sie die Liste der archivierten Partnerberichte an",
	"partnerProvision.archive-provision-report": "Partnerbericht archivieren",
	"partnerProvision.restore-provision-report": "Partnerbericht wiederherstellen",
	"partnerProvision.reportDetail": "Detail des Partnerberichts anzeigen",
	"partnerProvision.createCreditNote": "Gutschrift des Bereitstellungspartners erstellen",
	"partnerProvision.recreateCreditNote": "Gutschrift des Bereitstellungspartners neu erstellen",
	"partnerProvision.removeCreditNote": "Gutschrift des Bereitstellungspartners entfernen",
	"partnerProvision.generateBulkCreditNote": "Gutschrift in großen Mengen generieren.",
	"partnerProvision.reportExport": "Partnerbericht exportieren",
	"partnerProvision.reportPartners": "Berichtspartnerliste anzeigen",
	"partnerProvision.generateReport": "Partnerbericht erstellen",
	"partnerProvision.partnerCommissionStatus": "Ändere den Status der Partnerkommission",
	"partnerProvision.deletePartnerCommission": "Partnerkommission entfernen",
	"partnerProvision.reportAddCustomer": "Kundenprovision erstellen",
	"partnerProvision.deleteCustomerCommission": "Kundenprovision entfernen",
	"partnerProvision.updateCustomerCommission": "Kundenprovision aktualisieren",
	"partnerProvision.updateCustomerCommissionPercentage": "Prozentsatz der Kundenprovision aktualisieren",
	"partnerProvision.resetCustomerCommission": "Kundenprovision wiederherstellen",
	"partnerProvision.resetCustomerCommissionStatus": "Den Status der Kundenprovision zurücksetzen",
	"partnerProvision.updatePartnerProvisionPercentage": "Prozentsatz der Partnerbereitstellung aktualisieren",
	"partnerProvision.resetCustomerCommissionPercentage": "Prozentsatz der Kundenprovision wiederherstellen",
	"partnerProvision.exportPartnerReport": "Exportpartnerbericht",
	"partnerProvision.customerCommissionStatusUpdate": "Status -Update der Kundenkommission",
	"partnerProvision.updatePartnerCommissionStatus": "Aktualisierung der Partnerkommission Status",

	"customerPhoenixLicense.canSetUnlimitedExpiryDate": "Unbegrenztes Ablaufdatum festlegen",
	"customerPhoenixLicense.changeMasterPassword": "Ändere das Master-Passwort",
	"customerPhoenixLicense.companyIndex": "Alle Firmenlisten anzeigen",
	"customerPhoenixLicense.companyUpdate": "Firmenstatus aktualisieren",
	"customerPhoenixLicense.modulesIndex": "Alle Unternehmensmodullisten anzeigen",
	"customerPhoenixLicense.modulesUpdate": "Firmenmodul aktualisieren",
	"customerPhoenixLicense.subscriptionsIndex": "Alle Unternehmensabonnements anzeigen",
	"customerPhoenixLicense.subscriptionsShow": "Firmenabonnement anzeigen",
	"customerPhoenixLicense.subscriptionsStore": "Firmenabonnement erstellen",

	"invoices.list": "Rechnungsliste",
	"invoices.getUploadFileTempCredential": "Rufen Sie die Anmeldeinformationen ab, um die Rechnung hochzuladen",
	"invoices.groups.list": "Liste aller Rechnungsgruppen",
	"invoices.groups.list-active-invoice-groups": "Liste der aktiven Rechnungsgruppen",
	"invoices.groups.list-archived-invoice-groups": "Liste der archivierten Rechnungsgruppen",
	"invoices.create": "Rechnung erstellen",
	"invoices.removeInvoice": "Rechnung entfernen",
	"invoice-groups.addRequest": "Anfrage zum Hinzufügen einer Rechnungsgruppe",
	"inventory-group.listInventoryGroup": "Inventargruppenliste",
	"inventory-group.createInventoryGroup": "Inventargruppe erstellen",
	"inventory-group.getInventoryGroup": "Inventargruppe abrufen",
	"inventory-group.updateInventoryGroup": "Inventargruppe aktualisieren",
	"inventory-group.deleteInventoryGroup": "Inventargruppe löschen",
	"inventory-group.getSuggestion": "Inventarvorschlag erhalten",
	"invoices.create-and-send-email": "Rechnung erstellen und als E-Mail versenden",
	"invoices.generate-shopify": "Shopify-Rechnung erstellen",
	"invoices.groups.archive": "Gruppe Rechnungen archivieren",
	"invoices.groups.restore": "Rechnungsgruppe wiederherstellen",
	"invoices.update-status": "Rechnung als erledigt markieren",
	"invoices.change-status": "Status einer Rechnung ändern",
	"invoices.generatePDF": "PDF der SevDesk-Rechnung generieren",
	"invoices.customers.list": "Holen Sie sich die Kundenliste, deren Rechnungsdokument erstellt wurde",
	"invoices.customers.documents.list": "Dokumentenliste abrufen",
	"invoices.customers.documents.download": "Dokument herunterladen",
	"inventory-group.changeInventoryGroupStatus": "Ändern Sie den Bestandsgruppenstatus",
	"invoices.create-invoice-and-send-email-mark-complete": "Rechnung erstellen und E -Mail senden",
	"invoices.invoice-number": "Rechnungsnummer",
	"invoices.fetchInvoiceStatus": "Rechnungsstatus erhalten",

	"sev-desk.inventories.sync": "SevDesk-Inventarsynchronisierung",
	"sev-desk.inventories.list": "SevDesk-Inventarliste",

	"tax-rules.list-sev-desk-tax-rules": "Liste der Steuerregeln",

	"toolREA.reaList": "REA-Liste anzeigen",
	"list.inventories": "Inventarliste",
	"inventories.changeDisplayInInvoiceStatus": "Anzeige im Rechnungsstatus ändern",
	"invoice.turnover-statistics": "Umsatzstatistik anzeigen",
	"organizations.initial-sync-new-fields": "Neue Felder initial synchronisieren",

	"history-log.machine": "Verlaufsprotokolle für Maschine",
	"history-log.list-posbill-license-history": "Verlaufsprotokolle für Lizenz",
	"history-log.list-organization-change-history": "Verlaufsprotokolle für den Zahlungsstatus",
	"history-log.list-posbill-machine-auto-update-history": "Verlaufsprotokolle für den Status der automatischen Aktualisierung",
	"history-log.user-activities": "Verlaufsprotokolle für Benutzeraktivitäten",
	"history-log.list-resigo-machine-history": "Verlaufsprotokolle für die Resigo-Maschine",
	"history-log.list-resigo-license-history": "Verlaufsprotokolle für die Resigo-Lizenz",
	"history-log.list-posbill-machine-history": "Maschinenverlaufsliste",

	"tax-rules.list-tax-rules": "Liste der Steuerregeln",
	"tax-rules.countries": "Länderliste für die Erstellung von Steuerregeln",
	"tax-rules.store": "Steuerregel erstellen",
	"tax-rules.delete": "Steuerregel löschen",

	// ----------------------------------------- Offer Tool ----------------------------------

	"OfferTool.listBundleGroups": "Bundles der Bundle-Gruppe auflisten",
	"OfferTool.getBundlesOfBundleGroup": "Bundles der Bundle-Gruppe auflisten",
	"OfferTool.createBundleGroup": "Bundle-Gruppe erstellen",
	"OfferTool.updateBundleGroup": "Bundle-Gruppe aktualisieren",
	"OfferTool.listBundles": "Bundles auflisten",
	"OfferTool.createBundle": "Bundle erstellen",
	"OfferTool.updateBundle": "Bundle aktualisieren",
	"OfferTool.addBundleToBundleGroup": "Bundles zur Bundle-Gruppe hinzufügen",
	"OfferTool.removeBundleFromBundleGroup": "Bundle aus Bundle-Gruppe entfernen",
	"OfferTool.addProduct": "Produkt hinzufügen",
	"OfferTool.updateProduct": "Produkt aktualisieren",
	"OfferTool.getProducts": "Produkte auflisten",
	"OfferTool.getBundleProducts": "Produkte eines Bundles auflisten",

	// -------------------------------------------------------------------------------------

	// ----------------------------------------- Fiskaly (tseRegister) ----------------------------------

	"tseRegister.getTaxpayer": "Erhalten Sie Informationen zum Steuerzahler",
	"tseRegister.createOrUpdateTaxpayer": "Erstellen oder aktualisieren Sie Steuerzahlerinformationen",

	"tseRegister.listEstablishments": "Abrufen der Liste der Einrichtungen",
	"tseRegister.getEstablishment": "Abrufen einer Einrichtung",
	"tseRegister.createEstablishment": "Erstellen einer Einrichtung",
	"tseRegister.updateEstablishment": "Aktualisieren einer Einrichtung",
	"tseRegister.deleteEstablishment": "Löschen einer Einrichtung",

	"tseRegister.listTSS": "Abrufen der Liste der TSS",
	"tseRegister.getTSS": "Abrufen von TSS",
	"tseRegister.createTSS": "Erstellen von TSS",
	"tseRegister.updateTSS": "Aktualisieren von TSS",
	"tseRegister.deleteTSS": "Löschen von TSS",

	"tseRegister.listClients": "Kundenliste abrufen",
	"tseRegister.getClient": "Holen Sie sich den Kunden",
	"tseRegister.createClient": "Client erstellen",
	"tseRegister.updateClient": "Client aktualisieren",
	"tseRegister.deleteClient": "Client löschen",

	// -------------------------------------------------------------------------------------

	"posbill-backup.uploadStatistics": "Statistiken hochladen",
	"posbill-backup.customers": "Kunden holen",
	"posbill-backup.customer.uploadStatistics": "Statistiken hochladen",
	"posbill-backup.customer.backups": "Backups bekommen",
	"posbill-backup.customer.backups.requestDownload": "Anfrage Download -Backups",
	"posbill-backup.listDownloadRequests": "Laden Sie die Anforderungsliste herunter",
	"posbill-backup.backup-download": "Backup herunterladen",

	"customerInvoices.listInvoices": "Listen Sie Kundenrechnungen auf",
	"customerInvoices.downloadInvoices": "Laden Sie Kundenrechnungen herunter",
	"customerInvoices.sendInvoice": "Kundenrechnung senden",

	"sev-desk.tax-rules.list": "SevDesk Steuerregelliste",
	'mo': 'Mo',
	'di': 'Di',
	'mi': 'Mo',
	'do': 'Do',
	'fr': 'Fr',
	'sa': 'Sa',
	'so': 'So',
	'handler_update': "Händler Update",
	"fiskaly": "Fiskaly",
	"taxpayerInformation": "Informationen für Steuerzahler",
	"basicInformation": "Grundinformationen",
  	"taxInformation": "Steuerinformationen",
	"firstName": "Vorname",
	"lastName": "Nachname",
	"birthDate": "Geburtsdatum",
	"identificationNumber": "Identifikationsnummer",
	"title": "Titel",
	"prefix": "Präfix",
	"suffix": "	Suffix",
	"salutation": "Anrede",
	"houseNumber": "Hausnummer",
	"houseNumberSuffix": "Hausnummernsuffix",
	"addressAdditional": "Adresse zusätzlich",
	"town": "Stadt",
	"personType": "Personentyp",
	"taxNumber": "Steuernummer",
	"vatNumber": "Umsatzsteuer-Identifikationsnummer",
	"taxOfficeNumber": "Finanzamtnummer",
	"male": "Männlich",
	"female": "Weiblich",
	"other": "Andere",
	"natural": "Natürlich",
	"legal": "Legal",
	"legalForm": "Unternehmenstyp",
	"metadata": "Metadaten",
	"addMetadata": "Metadaten hinzufügen",

	"aktiengesellschaft": "Aktiengesellschaft",
	"aktiengesellschaftUCoKG": "Aktiengesellschaft u. Co. KG",
	"aktiengesellschaftUCoOHG": "Aktiengesellschaft u. Co. OHG",
	"andereErwerbsOderWirtschaftsgenossenschaft": "Andere Erwerbs- oder Wirtschaftsgenossenschaft",
	"atypischeStilleGesellschaft": "Atypische stille Gesellschaft",
	"auslandischeKorpergesellschaft": "Ausländische Körperschaft",
	"auslandischePersonengesellschaft": "Ausländische Personengesellschaft",
	"bergrechtlicheGewerkschaft": "Bergrechtliche Gewerkschaft",
	"europaischeAktiengesellschaft": "Europäische Aktiengesellschaft",
	"europaischeWirtschaftlicheInteressenvereinigung": "Europäische wirtschaftliche Interessenvereinigung",
	"gebietskorpergesellschaft": "Gebietskörperschaft",
	"gesellschaftDesBurgerlichenRechts": "Gesellschaft des bürgerlichen Rechts",
	"gesellschaftMitBeschrankterHaftung": "Gesellschaft mit beschränkter Haftung",
	"gmbHUCoKG": "GmbH u. Co. KG",
	"gmbHUCoOHG": "GmbH u. Co. OHG",
	"kolonialgesellschaft": "Kolonialgesellschaft",
	"kommanditgesellschaft": "Kommanditgesellschaft",
	"kommanditgesellschaftAufAktien": "Kommanditgesellschaft auf Aktien",
	"kreditgenossenschaftDieKrediteAusschließlichAnIhreMitgliederGewahrt": "Kreditgenossenschaft, die Kredite ausschließlich an ihre Mitglieder gewährt",
	"landwirtschaftlicheNutzungsOderVerwaltungsgenossenschaft": "Landwirtschaftl. Nutzungs- oder Verwaltungsgenossenschaft",
	"nichtrechtsfahigerVereinAnstaltStiftungOderAnderesZweckvermogen": "Nichtrechtsfähiger Verein, Anstalt, Stiftung oder anderes Zweckvermögen",
	"offeneHandelsgesellschaft": "Offene Handelsgesellschaft",
	"offentlicheOderUnterStaatsaufsichtStehendeSparkasse": "Öffentliche oder unter Staatsaufsicht stehende Sparkasse",
	"offentlichRechtlicheReligionsgesellschaft": "Öffentl.-rechtl. Religionsgesellschaft",
	"offentlichRechtlicheVersorgungsVerkehrsOderHafenbetrieb": "Öffentl.-rechtl. Versorgungs-, Verkehrs- oder Hafenbetrieb",
	"personMitBeteiligungenAnGewerblPersonengesellschaften": "Person mit Beteiligungen an gewerbl. Personengesellschaften",
	"realgemeinde": "Realgemeinde",
	"sceEuropaischeGenossenschaft": "SCE Europäische Genossenschaft",
	"sonstigeAuslandischeRechtsform": "Sonstige ausländische Rechtsform",
	"sonstigeJuristischePersonDesPrivatenRechts": "Sonstige juristische Person des privaten Rechts",
	"sonstigeJuristischePersonenDesOffentlichenRechts": "Sonstige juristische Personen des öffentlichen Rechts",
	"sonstigeKapitalgesellschaft": "Sonstige Kapitalgesellschaft",
	"sonstigeKreditanstaltDesOffentlichenRechts": "Sonstige Kreditanstalt des öffentlichen Rechts",
	"sonstigePersonengesellschaft": "Sonstige Personengesellschaft (z.B. Grundstücksgemeinschaft o.Ä.)",
	"sonstigerBetriebGewerblicherArtVonJurPersonenDesOffentlichenRechts": "Sonstiger Betrieb gewerblicher Art von jur. Personen des öffentlichen Rechts",
	"staatsbank": "Staatsbank",
	"unternehmergesellschaft": "Unternehmergesellschaft (haftungsbeschränkt)",
	"versicherungsvereinAufGegenseitigkeit": "Versicherungsverein auf Gegenseitigkeit",
	"zentralkasseDieKrediteAusschließlichAnIhreMitgliederGewahrtUndSichAufGenossenschaftlicheAufgabenBeschrankt": "Zentralkasse, die Kredite ausschließlich an ihre Mitglieder gewährt und sich auf genossenschaftl. Aufgaben beschränkt",

	"establishments": "Einrichtungen",
	"establishment": "Einrichtung",
	"establishmentList": "Betriebsliste",
	"createEstablishment": "Betrieb erstellen",
	"updateEstablishment": "Betrieb aktualisieren",
	"designation": "Bezeichnung",
	"remarks": "Bemerkungen",
	"tss": "TSS",
	"createTSS": "TSS erstellen",
	"updateTSS": "TSS aktualisieren",
	"tssSerialNumber": "Seriennummer",
	"sdCard": "SD-Karte",
	"usbStick": "USB-Stick",
	"cloud": "Cloud",
	"bsiId": "BSI Id",
	"dateCommissioning": "Datum der Inbetriebnahme",
	"createClient": "Client erstellen",
	"updateClient": "Client aktualisieren",
	"pcBasedPos": "Computergestützte/PC-Kassensysteme",
	"tabletAppPos": "Tablet-/App-Kassen-Systeme",
	"electronicCashRegister": "Elektronische Registrierkassen",
	"taximeter": "Taxameter",
	"distanceMeter": "Wegstreckenzähler",
	"dateAcquisition": "Erwerbsdatum ",
	"dateDecommissioning": "Stilllegungsdatum",
	"decommissioningReason": "Grund für die Stilllegung",
	"manufacturer": "Hersteller",
	"softwareVersion": "Softwareversion",
	"model": "Modell",
}
export default de;
